import React, {useState, useEffect, useRef} from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import {XCircle, Save} from "react-feather";
import {useForm} from "react-hook-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPen, faStar, faTrash} from '@fortawesome/free-solid-svg-icons';
import {DELETE, FILE, GET, POST, PUT} from "../../../api/adminApi";
import {showLoader, hideLoader} from "../../../store/actions/loader";
import {store} from "../../../store";
import Fileinput from "../../common/Fileinput";
import {Button} from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import {Calendar, Dropdown} from "antd";
import Select from "react-select";

function ShopProductsCrud() {
    let {id} = useParams();
    let history = useHistory();
    const inputRef = useRef();
    const {
        register,
        handleSubmit,
        formState: {errors},
        setValue,
        getValues,
    } = useForm({
        regionHelperDTO: {
            id: 0,
            name: "",
        },
        cityHelperDTO: {
            id: 0,
            name: "",
        },
        activated: true,
        brandTypeIdList: [],
        cityId: 0,
        companyId: 0,
        countryId: 0,
        regionId: 0,
        shopId: 0,
        nameUz: "",
        nameRu: "",
        nameEn: "",
        descriptionUz: "",
        descriptionRu: "",
        descriptionEn: "",
        estimatedYear: "",
        contactPhone: "",
        productCreateImageList: "",
    });
    const [regions, setRegions] = useState([]);
    const [cities, setCities] = useState([]);
    const [data, setData] = useState({
        imageUrl: "",
        iconImageUrl: "",
        activated: true,
    });
    const [resData, setResData] = useState();
    const [pickedDate, setPickedDate] = useState(new Date());
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [imageUrls, setImageUrls] = useState([]);
    const [imageUrlError, setImageUrlError] = useState(false);
    const lastDateOfYear = `12/31/${new Date().getFullYear()}`;
    const [shops, setShops] = useState([]);
    const [categories, setCategories] = useState([]);
    const [bazars, setBazars] = useState([]);

    const handleFileChange = async (e) => {
        const files = e.target.files;
        const filesArray = Array.from(files).map((file) => file);
        setSelectedFiles([...selectedFiles, ...filesArray]);

        const uploadedUrls = await uploadFiles(filesArray);
        setImageUrls([...imageUrls, ...uploadedUrls]);
    };

    const uploadFiles = async (files) => {
        try {
            const uploadedUrls = [];
            const formData = new FormData();
            files.forEach((file) => {
                formData.append("image", file);
            });
            const response = await FILE("/services/admin/api/product-image-upload", formData);
            const mainImage = response.url;

            uploadedUrls.push(mainImage);
            return uploadedUrls;
        } catch (error) {
            console.error("Error uploading files:", error);
            return [];
        }
    };

    async function getProduct() {
        const response = await GET("/services/admin/api/product/" + id, false);
        setResData(response);
        for (let key in response) {
            setValue(`${key}`, response[key]);
        }
        getRegions();
        setData({
            ...data
        });
    }

    async function getRegions() {
        const response = await GET("/services/admin/api/region-helper/1", false);
        const formattedRegions = response.map(({id, name}) => ({ value: id, label: name }));
        setRegions(formattedRegions);
        if (!id) {
            setValue("regionId", formattedRegions[0].value);
            getCities(formattedRegions[0].value);
        } else {
            getCities(getValues("regionId"));
            setValue("regionId", getValues("regionId"));
        }
    }
    async function getCities(data) {
        const response = await GET("/services/admin/api/city-helper/" + data, false);
        const formattedCities = response.map(({id, name}) => ({ value: id, label: name }));
        setCities(formattedCities);
        if (!id) {
            setValue("cityId", formattedCities[0].value);
        }
    }

    async function getShops() {
        const response = await GET("/services/admin/api/shop-helper", false);
        const formattedShops = response.map(({id, name}) => ({ value: id, label: name }));
        setShops(formattedShops);
    }

    async function getBazars() {
        const response = await GET("/services/admin/api/bazar-helper", false);
        const formattedBazars = response.map(({id, nameRu}) => ({ value: id, label: nameRu }));
        setBazars(formattedBazars);
    }


    async function getCategories() {
        const response = await GET("/services/admin/api/categories-helper", false);
        const formattedCategories = response.map(({id, name}) => ({ value: id, label: name }));
        setCategories(formattedCategories);
    }

    async function create(data) {
        data.expireDate = pickedDate.toISOString().split('T')[0];
        if (data.productImageList && data.productImageList.length > 0) {
            setImageUrlError(false);
            data.productCreateImageList = imageUrls.map((url) => ({
                imageUrl: url,
                main: false,
            }));
        } else {
            if (imageUrls.length === 0) {
                setImageUrlError(true);
                return;
            }
            data.productCreateImageList = imageUrls.map((url, index) => ({
                imageUrl: url,
                main: index === 0,
            }));
        }

        let response;
        if (id) {
            response = await PUT("/services/admin/api/product", data);
        } else {
            response = await POST("/services/admin/api/product", data);
        }
        if (response) {
            history.push("/admin/products");
        }
    }

    async function getData() {
        store.dispatch(showLoader());
        if (id) {
            await getProduct();
        } else {
            await getRegions();
            await getShops();
            await getCategories();
            await getBazars();
        }
        store.dispatch(hideLoader());
    }

    async function deleteImage(id) {
        await DELETE("/services/admin/api/product/image/" + id);
        getData();
    }

    async function makeMain(id) {
        await PUT("/services/admin/api/product-image-main/" + id + '/' + resData.id);
        getData();
    }
    useEffect(() => {
        setImageUrlError(false);
    }, [imageUrls]);

    useEffect(() => {
        getData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="mb-2 mx-3 ">
            <h3 className="mb-3 mt-1">Добавить новый</h3>
            <div className="admin-card p-4">
                <form onSubmit={handleSubmit(create)} autoComplete="off" action="">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Название товара(UZ)</label>
                                    </div>
                                    <input
                                        {...register("nameUz", {required: true})}
                                        type="text"
                                        placeholder=""
                                        className="input-control"
                                    />
                                </div>
                                {errors.nameUz?.type === "required" && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Название товара(RU)</label>
                                    </div>
                                    <input
                                        {...register("nameRu", {required: true})}
                                        type="text"
                                        placeholder=""
                                        className="input-control"
                                    />
                                </div>
                                {errors.nameRu?.type === "required" && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Название товара(EN)</label>
                                    </div>
                                    <input
                                        {...register("nameEn", {required: true})}
                                        type="text"
                                        placeholder=""
                                        className="input-control"
                                    />
                                </div>
                                {errors.nameEn?.type === "required" && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Контактный номер</label>
                                    </div>
                                    <input
                                        {...register("contactPhone", {required: true})}
                                        type="number"
                                        placeholder=""
                                        className="input-control"
                                    />
                                </div>
                                {errors.contactPhone?.type === "required" && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Старая цена</label>
                                    </div>
                                    <input
                                        {...register("oldPrice", {required: true})}
                                        type="number"
                                        placeholder="сум"
                                        className="input-control"
                                    />
                                </div>
                                {errors.oldPrice?.type === "required" && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Базар</label>
                                    </div>
                                    <Select
                                        className="admin-select"
                                        options={bazars}
                                        value={bazars.find(bazar => bazar.value === getValues('bozorId'))}
                                        onChange={(selectedOption) => {
                                            setValue("bozorId", selectedOption.value);
                                        }}
                                    />
                                </div>
                                {errors.bozorId?.type === "required" && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>

                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Магазины</label>
                                    </div>
                                    <Select
                                        className="admin-select"
                                        options={shops}
                                        value={shops.find(shop => shop.value === getValues('shopId'))}
                                        onChange={(selectedOption) => {
                                            setValue("shopId", selectedOption.value);
                                        }}
                                    />
                                </div>
                                {errors.shopId?.type === "required" && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>

                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Категории</label>
                                    </div>
                                    <Select
                                        className="admin-select"
                                        options={categories}
                                        value={categories.find(category => category.value === getValues('categoryId'))}
                                        onChange={(selectedOption) => {
                                            setValue("categoryId", selectedOption.value);
                                        }}
                                    />
                                </div>
                                {errors.categoryId?.type === "required" && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>



                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3"><label className="mb-0">Срок годности</label></div>
                                    {/* <input {...register("birthday", { required: true })} type="date" className="input-control" /> */}
                                    <DatePicker
                                        className='input-control'
                                        showYearDropdown
                                        dateFormatCalendar="MMMM"
                                        yearDropdownItemNumber={60}
                                        scrollableYearDropdown
                                        maxDate={new Date(lastDateOfYear)}
                                        // maxDate={moment().startOf('year')}
                                        dateFormat="yyyy-MM-dd"
                                        selected={pickedDate}
                                        onChange={(date) => (setPickedDate(date) + setValue('expireDate', moment(date).format("YYYY-MM-DD")))} />
                                </div>
                                {errors.expireDate?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
                            </div>
                            <div className="admin-field d-flex">
                                <div className="d-flex w-100">
                                    <div className='admin-label'><label className="mb-0">Фото</label></div>
                                    <div className='input-control border-none'>
                                        <div className="position-relative">
                                            <Fileinput
                                                name="mainImage"
                                                selectedFiles={selectedFiles}
                                                onChange={handleFileChange}
                                                multiple
                                                preview
                                            />
                                        </div>
                                        {imageUrlError && (
                                            <div className="text-danger mt-1 fz12">
                                                Обязательное поле
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Описание товара(UZ)</label>
                                    </div>
                                    <textarea
                                        {...register("descriptionUz", {required: true})}
                                        type="text"
                                        placeholder=""
                                        className="input-control"
                                    />
                                </div>
                                {errors.descriptionUz?.type === "required" && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Описание товара(RU)</label>
                                    </div>
                                    <textarea
                                        {...register("descriptionRu", {required: true})}
                                        type="text"
                                        placeholder=""
                                        className="input-control"
                                    />
                                </div>
                                {errors.descriptionRu?.type === "required" && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Описание товара(EN)</label>
                                    </div>
                                    <textarea
                                        {...register("descriptionEn", {required: true})}
                                        type="text"
                                        placeholder=""
                                        className="input-control"
                                    />
                                </div>
                                {errors.descriptionEn?.type === "required" && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Новая цена</label>
                                    </div>
                                    <input
                                        {...register("newPrice", {required: true})}
                                        type="number"
                                        placeholder="сум"
                                        className="input-control"
                                    />
                                </div>
                                {errors.newPrice?.type === "required" && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>


                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Регион</label>
                                    </div>
                                    <Select
                                        className="admin-select"
                                        options={regions}
                                        value={regions.find(region => region.value === getValues('regionId'))}
                                        onChange={(selectedOption) => {
                                            setValue("regionId", selectedOption.value);
                                            getCities(selectedOption.value);  // fetch cities based on selected region
                                        }}
                                    />
                                </div>
                                {errors.regionId?.type === "required" && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Город (район)</label>
                                    </div>
                                    <Select
                                        className="admin-select"
                                        options={cities}
                                        value={cities.find(city => city.value === getValues('cityId'))}
                                        onChange={(selectedOption) => {
                                            setValue("cityId", selectedOption.value);
                                        }}
                                    />
                                </div>
                                {errors.cityId?.type === "required" && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>

                        </div>
                    </div>
                    <div
                        style={{width: 1200, height: "100%", display: "flex", flexDirection: "row", overflowX: 'auto'}}>
                        {!resData || resData.productImageList.map((image, index) => {
                            const items = [
                                {
                                    label: (
                                        <button
                                            style={{border: 'none', outline: 'none', background: "transparent"}}
                                            onClick={() => makeMain(image.id)}
                                        >
                                            Сделать главной
                                        </button>
                                    ),
                                    key: '0',
                                },
                                {
                                    label: (
                                        <button
                                            style={{border: 'none', outline: 'none', background: "transparent"}}
                                            onClick={() => deleteImage(image.id)}
                                        >
                                            Удалить
                                        </button>
                                    ),
                                    key: '1',
                                }
                            ];
                            return (
                                <div key={image.id} style={{position: 'relative', display: 'inline-block'}}>
                                    <div style={{height: 300}}>
                                        <div
                                            style={{
                                                width: 200,
                                                height: 200,
                                                border: "3px solid #c9c9c9",
                                                borderRadius: 5,
                                                marginRight: 20,
                                                background: `url(https://bozormedia.uz${image.imageUrl})`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                                position: "relative",
                                            }}
                                        >
                                            {image.main ? (
                                                <FontAwesomeIcon
                                                    icon={faStar}
                                                    size="lg"
                                                    style={{
                                                        color: '#1976d2',
                                                        cursor: 'pointer',
                                                        position: 'absolute',
                                                        right: 10,
                                                        top: 10,
                                                    }}
                                                />) : null}
                                            {image.main ? null : (
                                                <Dropdown
                                                    menu={{
                                                        items,
                                                    }}
                                                    trigger={['click']}
                                                >
                                                    <a onClick={(e) => e.preventDefault()}
                                                       style={{
                                                           position: "absolute",
                                                           right: -10,
                                                           bottom: -10,
                                                           width: 30,
                                                           height: 30,
                                                           display: 'flex',
                                                           alignItems: "center",
                                                           justifyContent: 'center',
                                                           borderRadius: '50%',
                                                           backgroundColor: "#bbdefb"
                                                       }}>
                                                        <FontAwesomeIcon
                                                            icon={faPen}
                                                            style={{
                                                                width: 16,
                                                                height: 16,
                                                                color: '#42a5f5',
                                                                cursor: 'pointer',
                                                                position: "absolute",
                                                            }}
                                                        />
                                                    </a>
                                                </Dropdown>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <div className="btn-postion d-flex">
                        <Link to="/admin/product" className="mr-3">
                            <Button className="btn bg-dark d-flex align-items-center">
                                <XCircle size={14} className="mr-2"/>
                                Отмена
                            </Button>
                        </Link>
                        <Button
                            type="submit"
                            className="btn bg-primary d-flex align-items-center"
                        >
                            <Save size={14} className="mr-2"/>
                            Сохранить
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ShopProductsCrud;
