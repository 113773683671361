import React, {useState, useEffect, useRef} from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import {XCircle, Save} from "react-feather";
import {useForm} from "react-hook-form";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPen, faStar} from '@fortawesome/free-solid-svg-icons';
import {DELETE, FILE, GET, POST, PUT} from "../../../api/adminApi";
import {showLoader, hideLoader} from "../../../store/actions/loader";
import {store} from "../../../store";
import Fileinput from "../../common/Fileinput";
import {Dropdown} from "antd";
import {Button} from "react-bootstrap";
import Select from "react-select";

function BazarAddUpdate() {
    let {id} = useParams();
    let history = useHistory();
    const {
        register,
        handleSubmit,
        formState: {errors},
        setValue,
        getValues,
    } = useForm({
        regionHelperDTO: {
            id: 0,
            name: "",
        },
        cityHelperDTO: {
            id: 0,
            name: "",
        },
        activated: true,
        brandTypeIdList: [],
        cityId: 0,
        companyId: 0,
        countryId: 0,
        regionId: 0,
        nameUz: "",
        nameRu: "",
        nameEn: "",
        descriptionUz: "",
        descriptionRu: "",
        descriptionEn: "",
        estimatedYear: "",
        bazarCreateImageList: "",
    });
    const [regions, setRegions] = useState([]);
    const [cities, setCities] = useState([]);
    const [data, setData] = useState({
        mainImage: "",
        iconImageUrl: "",
        activated: true,
    });
    const [resData, setResData] = useState();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [imageUrls, setImageUrls] = useState([]);
    const [imageUrlError, setImageUrlError] = useState(false);
    const [selectedRegion, setSelectedRegion] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const handleFileChange = async (e) => {
        const files = e.target.files;
        const filesArray = Array.from(files).map((file) => file);
        setSelectedFiles([...selectedFiles, ...filesArray]);

        const uploadedUrls = await uploadFiles(filesArray);
        setImageUrls([...imageUrls, ...uploadedUrls]);
    };

    const uploadFiles = async (files) => {
        try {
            const uploadedUrls = [];
            const formData = new FormData();
            files.forEach((file) => {
                formData.append("image", file);
            });
            const response = await FILE("/services/admin/api/bazar-image-upload", formData);
            const mainImage = response.url;

            uploadedUrls.push(mainImage);
            return uploadedUrls;
        } catch (error) {
            console.error("Error uploading files:", error);
            return [];
        }
    };

    async function getProduct() {
        const response = await GET("/services/admin/api/bazar/" + id, false);
        setResData(response);
        for (let key in response) {
            setValue(`${key}`, response[key]);
        }

        const matchedRegion = regions.find(region => region.value === response.regionId);
        setSelectedRegion(matchedRegion);

        await getCities(response.regionId);
        const matchedCity = cities.find(city => city.value === response.cityId);
        setSelectedCity(matchedCity);

        setData({
            ...data
        });
    }



    async function getRegions() {
        try {
            const response = await GET("/services/admin/api/region-helper/1", false);
            setRegions(response.map(region => ({ value: region.id, label: region.name })));

            if (!id && response.length > 0) {
                const firstRegion = response[0];
                setValue("regionId", firstRegion.id);
                setValue("regionHelperDTO.name", firstRegion.name);
                setValue("regionHelperDTO.id", firstRegion.id);

                getCities(firstRegion.id);
            } else {
                const regionIdValue = getValues("regionId");
                if (regionIdValue) {
                    getCities(regionIdValue);
                }
            }
        } catch (error) {
            console.error("Error getting regions:", error);
        }
    }

    async function getCities(regionId) {
        if (regionId === undefined) return Promise.resolve();

        try {
            const response = await GET(`/services/admin/api/city-helper/${regionId}`, false);
            setCities(response.map(city => ({ value: city.id, label: city.name })));

            if (!id && response.length > 0) {
                const firstCity = response[0];
                setValue("cityId", firstCity.id);
                setValue("cityHelperDTO.name", firstCity.name);
                setValue("cityHelperDTO.id", firstCity.id);
            }
            return Promise.resolve(response);
        } catch (error) {
            console.error("Error getting cities:", error);
            return Promise.reject(error);
        }
    }



    async function create(data) {
        if (data.bazarImageList && data.bazarImageList.length > 0) {
            setImageUrlError(false);
            data.bazarCreateImageList = imageUrls.map((url) => ({
                imageUrl: url,
                main: false,
            }));
        } else {
            if (imageUrls.length === 0) {
                setImageUrlError(true);
                return;
            }
            data.bazarCreateImageList = imageUrls.map((url, index) => ({
                imageUrl: url,
                main: index === 0,
            }));
        }

        let response;
        if (id) {
            response = await PUT("/services/admin/api/bazar", data);
        } else {
            response = await POST("/services/admin/api/bazar", data);
        }
        if (response) {
            history.push("/admin/bazar");
        }
    }


    async function getData() {
        store.dispatch(showLoader());
        if (id) {
            await getProduct();
        } else {
            await getRegions();
        }
        store.dispatch(hideLoader());
    }

    useEffect(() => {
        async function fetchData() {
            store.dispatch(showLoader());
            await getRegions();
            if (id) {
                await getProduct();
            }
            store.dispatch(hideLoader());
        }

        fetchData();
    }, []);
    async function deleteImage(id) {
        await DELETE("/services/admin/api/bazar/image/" + id);
        getData();
    }

    async function makeMain(id) {
        await PUT("/services/admin/api/bazar-image-main/" + id + '/' + resData.id);
        getData();
    }

    const onSubmit = data => create(data);

    useEffect(() => {
        setImageUrlError(false);
    }, [imageUrls]);

    useEffect(() => {
        getData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    console.log(resData)
    return (
        <div className="mb-2 mx-3 ">
            <h3 className="mb-3 mt-1">{id ? `Редактировать ${resData?.nameRu}`: 'Добавить новый'}</h3>
            <div className="admin-card p-4">
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" action="">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Название базара(UZ)</label>
                                    </div>
                                    <input
                                        {...register("nameUz", {required: true})}
                                        type="text"
                                        placeholder=""
                                        className="input-control"
                                    />
                                </div>
                                {errors.nameUz?.type === "required" && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Название базара(RU)</label>
                                    </div>
                                    <input
                                        {...register("nameRu", {required: true})}
                                        type="text"
                                        placeholder=""
                                        className="input-control"
                                    />
                                </div>
                                {errors.nameRu?.type === "required" && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Название базара(EN)</label>
                                    </div>
                                    <input
                                        {...register("nameEn", {required: true})}
                                        type="text"
                                        placeholder=""
                                        className="input-control"
                                    />
                                </div>
                                {errors.nameEn?.type === "required" && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Дата основания</label>
                                    </div>
                                    <input
                                        {...register("estimatedYear", {required: true})}
                                        type="text"
                                        placeholder=""
                                        className="input-control"
                                    />
                                </div>
                                {errors.estimatedYear?.type === "required" && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Регион</label>
                                    </div>
                                    <Select
                                        className="admin-select"
                                        options={regions}
                                        value={selectedRegion}
                                        onChange={selectedOption => {
                                            setSelectedRegion(selectedOption);
                                            setValue("regionId", selectedOption.value);
                                            getCities(selectedOption.value);
                                        }}
                                    />
                                </div>
                                {errors.regionId?.type === "required" && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                            <div className="admin-field d-flex">
                                <div className="d-flex w-100">
                                    <div className='admin-label'><label className="mb-0">Фото</label></div>
                                    <div className='input-control border-none'>
                                        <div className="position-relative">
                                            <Fileinput
                                                name="mainImage"
                                                selectedFiles={selectedFiles}
                                                onChange={handleFileChange}
                                                multiple
                                                preview
                                            />
                                        </div>
                                        {imageUrlError && (
                                            <div className="text-danger mt-1 fz12">
                                                Обязательное поле
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Описание базара(UZ)</label>
                                    </div>
                                    <textarea
                                        {...register("descriptionUz", {required: true})}
                                        type="text"
                                        placeholder=""
                                        className="input-control"
                                    />
                                </div>
                                {errors.descriptionUz?.type === "required" && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Описание базара(RU)</label>
                                    </div>
                                    <textarea
                                        {...register("descriptionRu", {required: true})}
                                        type="text"
                                        placeholder=""
                                        className="input-control"
                                    />
                                </div>
                                {errors.descriptionRu?.type === "required" && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Описание базара(EN)</label>
                                    </div>
                                    <textarea
                                        {...register("descriptionEn", {required: true})}
                                        type="text"
                                        placeholder=""
                                        className="input-control"
                                    />
                                </div>
                                {errors.descriptionEn?.type === "required" && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Город (район)</label>
                                    </div>
                                    <Select
                                        className="admin-select"
                                        options={cities}
                                        value={selectedCity}
                                        onChange={selectedOption => {
                                            setSelectedCity(selectedOption);
                                            setValue("cityId", selectedOption.value);
                                        }}
                                    />
                                </div>
                                {errors.cityId?.type === "required" && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>

                        </div>
                    </div>
                    <div
                        style={{width: 1200, height: "100%", display: "flex", flexDirection: "row", overflowX: 'auto'}}>
                        {!resData || resData.bazarImageList.map((image, index) => {
                            const items = [
                                {
                                    label: (
                                        <button
                                            style={{border: 'none', outline: 'none', background: "transparent"}}
                                            onClick={() => makeMain(image.id)}
                                        >
                                            Сделать главной
                                        </button>
                                    ),
                                    key: '0',
                                },
                                {
                                    label: (
                                        <button
                                            style={{border: 'none', outline: 'none', background: "transparent"}}
                                            onClick={() => deleteImage(image.id)}
                                        >
                                            Удалить
                                        </button>
                                    ),
                                    key: '1',
                                }
                            ];
                            return (
                                <div key={image.id} style={{position: 'relative', display: 'inline-block'}}>
                                    <div style={{height: 300}}>
                                        <div
                                            style={{
                                                width: 200,
                                                height: 200,
                                                border: "3px solid #c9c9c9",
                                                borderRadius: 5,
                                                marginRight: 20,
                                                background: `url(https://bozormedia.uz${image.imageUrl})`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                                position: "relative",
                                            }}
                                        >
                                            {image.main ? (
                                                <FontAwesomeIcon
                                                    icon={faStar}
                                                    size="lg"
                                                    style={{
                                                        color: '#1976d2',
                                                        cursor: 'pointer',
                                                        position: 'absolute',
                                                        right: 10,
                                                        top: 10,
                                                    }}
                                                />) : null}
                                            {image.main ? null : (
                                                <Dropdown
                                                    menu={{
                                                        items,
                                                    }}
                                                    trigger={['click']}
                                                >
                                                    <a onClick={(e) => e.preventDefault()}
                                                       style={{
                                                           position: "absolute",
                                                           right: -10,
                                                           bottom: -10,
                                                           width: 30,
                                                           height: 30,
                                                           display: 'flex',
                                                           alignItems: "center",
                                                           justifyContent: 'center',
                                                           borderRadius: '50%',
                                                           backgroundColor: "#bbdefb"
                                                       }}>
                                                        <FontAwesomeIcon
                                                            icon={faPen}
                                                            style={{
                                                                width: 16,
                                                                height: 16,
                                                                color: '#42a5f5',
                                                                cursor: 'pointer',
                                                                position: "absolute",
                                                            }}
                                                        />
                                                    </a>
                                                </Dropdown>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <div className="btn-postion d-flex">
                        <Link to="/admin/bazar" className="mr-3">
                            <Button className="btn bg-dark d-flex align-items-center">
                                <XCircle size={14} className="mr-2"/>
                                Отмена
                            </Button>
                        </Link>
                        <Button
                            type="submit"
                            className="btn bg-primary d-flex align-items-center"
                        >
                            <Save size={14} className="mr-2"/>
                            Сохранить
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default BazarAddUpdate;
