import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { DebounceInput } from "react-debounce-input";
import ReactPaginate from "react-paginate";
import { Edit3, Search, PlusCircle, MapPin } from "react-feather";

import { GET, POST, PUT, PGET } from "../../../api/adminApi";
import {Link} from "react-router-dom";
import {CustomSvg} from "../../../utils/CustomSvg";

function Territory() {
   const [cities, setCities] = useState([]);
   const [regions, setRegions] = useState([]);
   const [countries, setСountries] = useState([]);
   const [active, setActive] = useState(0);
   const [method, setMethod] = useState("create");
   const [showModal, setShowModal] = useState(false);
   const [city, setCity] = useState({
      nameRu: "",
      nameUz: "",
      regionId: "",
      regionName: "",
   });
   const [region, setRegion] = useState({
      countryId: "",
      countryName: "",
      nameRu: "",
      nameUz: "",
   });
   const [country, setCountry] = useState({
      nameRu: "",
      nameUz: "",
   });
   const [pageCount, setPageCount] = useState(0);

   async function getItems(num, bool = true) {
      if (bool) {
         setActive(num);
      }
      if (num === 0) {
         const data = await PGET("/services/admin/api/city-pageList", true, {
            page: 0,
            size: 10,
         });
         setPageCount(Math.ceil(data.headers["x-total-count"] / 10));
         setCities(data.data);
      }
      if (num === 1) {
         const data = await PGET("/services/admin/api/region-pageList", true, {
            page: 0,
            size: 10,
         });
         setPageCount(Math.ceil(data.headers["x-total-count"] / 10));
         setRegions(data.data);
      }
      if (num === 2) {
         const data = await PGET("/services/admin/api/country-pageList", true, {
            page: 0,
            size: 10,
         });
         setPageCount(Math.ceil(data.headers["x-total-count"] / 10));
         setСountries(data.data);
      }
   }

   async function createItem() {
      if (active === 0) {
         await POST("/services/admin/api/city", city); // ?size=20&page=1
      }
      if (active === 1) {
         await POST("/services/admin/api/region", region); // ?size=20&page=1
      }
      if (active === 2) {
         await POST("/services/admin/api/country", country); // ?size=20&page=1
      }
      setShowModal(false);
      getItems(active, false);
   }
   async function updateItem() {
      if (active === 0) {
         await PUT("/services/admin/api/city", city); // ?size=20&page=1
      }
      if (active === 1) {
         await PUT("/services/admin/api/region", region); // ?size=20&page=1
      }
      if (active === 2) {
         await PUT("/services/admin/api/country", country); // ?size=20&page=1
      }
      setShowModal(false);
      getItems(active, false);
   }

   function onHide() {
      setCity({
         nameRu: "",
         nameUz: "",
         regionId: "",
         regionName: "",
      });
      setRegion({
         countryId: "",
         countryName: "",
         nameRu: "",
         nameUz: "",
      });
      setCountry({
         nameRu: "",
         nameUz: "",
      });
      setShowModal(false);
   }

   async function getItem(id) {
      if (active === 0) {
         const data = await GET("/services/admin/api/city/" + id);
         await getItems(1, false);
         setCity(data);
      }
      if (active === 1) {
         await getItems(2, false);
         const data = await GET("/services/admin/api/region/" + id);
         setRegion(data);
      }
      if (active === 2) {
         const data = await GET("/services/admin/api/country/" + id);
         setCountry(data);
      }
      setMethod("update");
      setShowModal(true);
   }
   async function search(e) {
      if (active === 0) {
         const data = await PGET(
            "/services/admin/api/city-pageList?search=" + e.target.value
         );
         setPageCount(Math.ceil(data.headers["x-total-count"] / 10));
         setCities(data.data);
      }
      if (active === 1) {
         const data = await PGET(
            "/services/admin/api/region-pageList?search=" + e.target.value
         );
         setPageCount(Math.ceil(data.headers["x-total-count"] / 10));
         setRegions(data.data);
      }
      if (active === 2) {
         const data = await PGET(
            "/services/admin/api/country-pageList?search=" + e.target.value
         );
         setPageCount(Math.ceil(data.headers["x-total-count"] / 10));
         setСountries(data.data);
      }
   }
   async function paginate(data) {
      if (active === 0) {
         const response = await PGET(
            "/services/admin/api/city-pageList",
            true,
            { page: data.selected, size: 10 }
         );
         setCities(response.data);
      }
      if (active === 1) {
         const response = await PGET(
            "/services/admin/api/region-pageList",
            true,
            { page: data.selected, size: 10 }
         );
         setRegions(response.data);
      }
      if (active === 2) {
         const response = await PGET(
            "/services/admin/api/country-pageList",
            true,
            { page: data.selected, size: 10 }
         );
         setСountries(response.data);
      }
   }

   useEffect(() => {
      getItems(0, true);
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   return (
      <>
         <div
             className="br-5 card"
             style={{background: '#eef1ff', marginTop: "-0.9rem", height: '100vh'}}
         >
            <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
               <span className='card-label fw-bold fs-3 mb-1'>Территории</span>
            </h3>
            </div>
            <div className="p-3">
               <div className="border-bottom mb-4 d-flex">
                  <div
                     className={
                        "territory-header " +
                        (active === 0
                           ? "territory-header-active box-shadow"
                           : "territory-header-disabled")
                     }
                     onClick={() => getItems(0, true)}
                  >
                     Города(районы){" "}
                  </div>

                  <div
                     className={
                        "territory-header " +
                        (active === 1
                           ? "territory-header-active box-shadow"
                           : "territory-header-disabled")
                     }
                     onClick={() => getItems(1, true)}
                  >
                     Регионы
                  </div>

                  <div
                     className={
                        "territory-header " +
                        (active === 2
                           ? "territory-header-active box-shadow"
                           : "territory-header-disabled")
                     }
                     onClick={() => getItems(2, true)}
                  >
                     Страны
                  </div>
               </div>

               {active === 0 ? (
                  <div>
                     <div className="mb-4 d-flex justify-content-between">
                        <div
                            className='card-toolbar'
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            data-bs-trigger='hover'
                            title='Click to add a user'
                        >
                           <button
                               onClick={() =>
                                   setShowModal(true) + getItems(1, false)
                               }
                               className='btn btn-sm btn-light-primary'
                               data-bs-toggle='modal'
                               data-bs-target='#kt_modal_invite_friends'
                           >
                              <CustomSvg path='/icons/duotune/arrows/arr075.svg' className='svg-icon-3'/>
                              Добавить новый
                           </button>
                        </div>
                        <div className="position-relative">
                           <DebounceInput
                              minLength={3}
                              debounceTimeout={400}
                              onChange={(e) => search(e)}
                              type="text"
                              className="input-search search-icon br-5 "
                              size={24}
                              placeholder="Поиск"
                           />
                        </div>
                     </div>

                     <div style={{ overflowX: "auto" }}>
                        <table className='table bg-white table-rounded table-striped border gy-3 gs-7'>
                           <thead>
                           <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                                 <th className="text-start">Название </th>
                                 <th className="text-start">Регион </th>
                                 <th className="text-center">Действие </th>
                              </tr>
                           </thead>

                           <tbody style={{ overflowX: "auto" }}>
                              {cities.map((item) => {
                                 return (
                                    <tr key={item.id}>
                                       <td className="vertical-middle text-start">
                                          {item.nameRu}
                                       </td>
                                       <td className="vertical-middle text-start">
                                          {item.regionName}
                                       </td>
                                       <td className="vertical-middle">
                                          <div className="d-flex justify-content-center">
                                             <button
                                                 onClick={() => getItem(item.id)}
                                                 className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                             >
                                                <CustomSvg path='/icons/duotune/art/art005.svg' className='svg-icon-3'/>
                                             </button>
                                          </div>
                                       </td>
                                    </tr>
                                 );
                              })}
                           </tbody>
                        </table>
                     </div>
                  </div>
               ) : // REGIONS

               active === 1 ? (
                  <div>
                     <div className="mb-4 d-flex justify-content-between">

                        <div
                            className='card-toolbar'
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            data-bs-trigger='hover'
                            title='Click to add a user'
                        >
                           <button
                               onClick={() =>
                                   setShowModal(true) + getItems(2, false)
                               }
                               className='btn btn-sm btn-light-primary'
                               data-bs-toggle='modal'
                               data-bs-target='#kt_modal_invite_friends'
                           >
                              <CustomSvg path='/icons/duotune/arrows/arr075.svg' className='svg-icon-3'/>
                              Добавить новый
                           </button>
                        </div>
                        <div className="position-relative">
                           <DebounceInput
                               minLength={3}
                               debounceTimeout={400}
                               onChange={(e) => search(e)}
                               type="text"
                               className="input-search search-icon br-5 "
                               size={24}
                               placeholder="Поиск"
                           />
                        </div>
                     </div>

                     <div style={{ overflowX: "auto" }}>
                        <table className="table background-white box-shadow">
                           <thead>
                              <tr className="backgroung-grey">
                                 <th>Название </th>
                                 <th>Регион </th>
                                 <th className="text-center">Действие </th>
                              </tr>
                           </thead>

                           <tbody style={{ overflowX: "auto" }}>
                              {regions.map((item) => {
                                 return (
                                    <tr key={item.id}>
                                       <td className="vertical-middle text-start">
                                          {item.nameRu}
                                       </td>
                                       <td className="vertical-middle text-start">
                                          {item.countryName}
                                       </td>
                                       <td className="vertical-middle">
                                          <div className="d-flex justify-content-center">
                                             <button
                                                 onClick={() =>
                                                     setMethod("update") +
                                                     getItem(item.id)
                                                 }
                                                 className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                             >
                                                <CustomSvg path='/icons/duotune/art/art005.svg' className='svg-icon-3'/>
                                             </button>

                                          </div>
                                       </td>
                                    </tr>
                                 );
                              })}
                           </tbody>
                        </table>
                     </div>
                  </div>
               ) : (
                  // COUNTRY

                  <div>
                     <div className="mb-4 d-flex justify-content-between">
                        <div
                            className='card-toolbar'
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            data-bs-trigger='hover'
                            title='Click to add a user'
                        >
                           <button
                               onClick={() => setShowModal(true)}
                               className='btn btn-sm btn-light-primary'
                               data-bs-toggle='modal'
                               data-bs-target='#kt_modal_invite_friends'
                           >
                              <CustomSvg path='/icons/duotune/arrows/arr075.svg' className='svg-icon-3'/>
                              Добавить новый
                           </button>
                        </div>
                        <div className="position-relative">
                           <DebounceInput
                               minLength={3}
                               debounceTimeout={400}
                               onChange={(e) => search(e)}
                               type="text"
                               className="input-search search-icon br-5 "
                               size={24}
                               placeholder="Поиск"
                           />
                        </div>
                     </div>

                     <div style={{ overflowX: "auto" }}>
                        <table className="table background-white box-shadow">
                           <thead>
                              <tr className="backgroung-grey">
                                 <th>Название </th>
                                 <th className="text-center">Действие </th>
                              </tr>
                           </thead>

                           <tbody style={{ overflowX: "auto" }}>
                              {countries.map((item) => {
                                 return (
                                    <tr key={item.id}>
                                       <td className="vertical-middle text-start">
                                          {item.nameRu}
                                       </td>
                                       <td className="vertical-middle">
                                          <div className="d-flex justify-content-center">
                                             <button
                                                 onClick={() =>
                                                     setMethod("update") +
                                                     getItem(item.id)
                                                 }
                                                 className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                             >
                                                <CustomSvg path='/icons/duotune/art/art005.svg' className='svg-icon-3'/>
                                             </button>
                                          </div>
                                       </td>
                                    </tr>
                                 );
                              })}
                           </tbody>
                        </table>
                     </div>
                  </div>
               )}
            </div>
            {pageCount > 1 && (
               <ReactPaginate
                  // breakLabel={'...'}
                  // breakClassName={'break-me'}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={paginate}
                  containerClassName={"pagination"}
                  activeClassName={"bg-dark text-white"}
                  pageClassName={"page-item box-shadow"}
               />
            )}
         </div>

         <Modal
            show={showModal}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => onHide()}
         >
            <Modal.Header closeButton>
               <Modal.Title id="contained-modal-title-vcenter">
                  {active === 0
                     ? "Добавить город"
                     : active === 1
                     ? "Добавить регион"
                     : "Добавить страну"}
               </Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <div className="d-flex flex-column w-100">
                  <div className="p-2">
                     <div className="d-flex flex-column mb-3 color-grey">
                        <label className="mb-1">Название(рус)</label>
                        <input
                           value={
                              active === 0
                                 ? city.nameRu
                                 : active === 1
                                 ? region.nameRu
                                 : country.nameRu
                           }
                           onChange={(e) =>
                              active === 0
                                 ? setCity({ ...city, nameRu: e.target.value })
                                 : active === 1
                                 ? setRegion({
                                      ...region,
                                      nameRu: e.target.value,
                                   })
                                 : setCountry({
                                      ...country,
                                      nameRu: e.target.value,
                                   })
                           }
                           type="text"
                           className="input-control"
                        />
                     </div>
                     <div className="d-flex flex-column mb-3 color-grey">
                        <label className="mb-1">Название(узб)</label>
                        <input
                           value={
                              active === 0
                                 ? city.nameUz
                                 : active === 1
                                 ? region.nameUz
                                 : country.nameUz
                           }
                           onChange={(e) =>
                              active === 0
                                 ? setCity({ ...city, nameUz: e.target.value })
                                 : active === 1
                                 ? setRegion({
                                      ...region,
                                      nameUz: e.target.value,
                                   })
                                 : setCountry({
                                      ...country,
                                      nameUz: e.target.value,
                                   })
                           }
                           type="text"
                           className="input-control"
                        />
                     </div>
                     {active === 2 ? (
                        ""
                     ) : (
                        <div className="d-flex flex-column mb-3 color-grey">
                           <label className="mb-1">
                              {active === 0
                                 ? "Выберите регион"
                                 : "Выберите страну"}
                           </label>
                           <select
                              className="input-control overflow-auto"
                              onChange={(e) =>
                                 active === 0
                                    ? setCity({
                                         ...city,
                                         regionId: regions[e.target.value].id,
                                         regionName:
                                            regions[e.target.value].nameRu,
                                      })
                                    : setRegion({
                                         ...region,
                                         countryId:
                                            countries[e.target.value].id,
                                         countryName:
                                            countries[e.target.value].nameRu,
                                      })
                              }
                           >
                              {active === 0
                                 ? regions.map((item, index) => {
                                      return (
                                         <option key={item.id} value={index}>
                                            {item.nameRu}
                                         </option>
                                      );
                                   })
                                 : active === 1
                                 ? countries.map((item, index) => {
                                      return (
                                         <option key={item.id} value={index}>
                                            {item.nameRu}
                                         </option>
                                      );
                                   })
                                 : ""}
                           </select>
                        </div>
                     )}
                  </div>
               </div>
               <div className="p-2 w-100">
                  <button
                     onClick={() =>
                        method === "create" ? createItem() : updateItem()
                     }
                     className="btn btn-outline-purple w-100"
                  >
                     Сохранить
                  </button>
               </div>
            </Modal.Body>
         </Modal>
      </>
   );
}

export default Territory;
