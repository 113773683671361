import React from "react";

const Fileinput = (
    {
        name,
        label = "Выбрать",
        onChange,
        placeholder = "Выберите или перетащите сюда файлы для загрузки...",
        multiple,
        preview,
        className = "custom-class",
        id,
        selectedFile,
        badge,
        selectedFiles,
        imageUrls,
        setImageUrls,
        setSelectedFiles
    }) => {

    const removeSelectedFile = (index) => {
        const newFiles = [...selectedFiles];
        const newImageUrls = [...imageUrls];

        newFiles.splice(index, 1);
        newImageUrls.splice(index, 1);

        setSelectedFiles(newFiles);
        setImageUrls(newImageUrls);
    };

    return (
        <div>
            <div className="form-group">
                <label style={{width: '100%'}}>
                    <input
                        type="file"
                        onChange={onChange}
                        className="form-control-file d-none"
                        name={name}
                        id={id}
                        multiple={multiple}
                        placeholder={placeholder}
                    />
                    <div className={`w-100 file-control d-flex align-items-center ${className}`}
                         style={{border: '1px solid #c9c9c9', width: '100%', borderRadius: 8, paddingLeft: 20}}>
                        {!multiple && (
                            <span className="flex-1 overflow-hidden text-nowrap" style={{flex: 1}}>
                {selectedFile && (
                    <span className={badge ? "badge badge-title" : "text-dark"}>
                    {selectedFile.name}
                  </span>
                )}
                                {!selectedFile && (
                                    <span className="text-muted">{placeholder}</span>
                                )}
              </span>
                        )}
                        {multiple && (
                            <span className="flex-1 overflow-hidden text-nowrap" style={{flex: 1}}>
                {selectedFiles.length > 0 && (
                    <span className={badge ? "badge badge-title" : "text-dark"}>
                    {selectedFiles.length > 0
                        ? selectedFiles.length + " выбрано"
                        : ""}
                  </span>
                )}
                                {selectedFiles.length === 0 && (
                                    <span className="text-muted">{placeholder}</span>
                                )}
              </span>
                        )}
                        <span style={{width: 100, height: 40, background: '#c9c9c9'}}
                              className="file-name flex-shrink-0 cursor-pointer border-left px-4 border-secondary d-inline-flex align-items-center text-black text-base rounded-end rounded-bottom font-weight-normal">
              {label}
            </span>
                    </div>
                    {!multiple && preview && selectedFile && (
                        <div className="w-200 h-200 mx-auto mt-6">
                            <img
                                src={selectedFile ? URL.createObjectURL(selectedFile) : ""}
                                className="w-100 h-100 block rounded object-fit-contain border p-2 border-secondary"
                                alt={selectedFile?.name}
                            />
                        </div>
                    )}
                    {multiple && preview && selectedFiles.length > 0 && (
                        <div className="d-flex flex-wrap gap-5 rtl-gap-reverse">
                            {selectedFiles.map((file, index) => (
                                <div
                                    className="col-xl-2 col-md-4 col-6 rounded mt-6 border p-2 border-secondary"
                                    key={index}
                                >
                                    <img
                                        src={`https://bozormedia.uz${imageUrls[index]}`}
                                        className="object-fit-cover w-100 h-100 rounded cursor-pointer"
                                        alt={file?.name}
                                        onClick={() => removeSelectedFile(index)}
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                </label>
            </div>
        </div>
    );
};

export default Fileinput;
