import React from "react";
import axios from "axios";
import {useHistory} from "react-router-dom";
import {GUESTPOST} from "../../api/api";
import {useForm} from "react-hook-form";
import img from '../../assets/imgBg/14.jpg'

const AdminLogin = () => {
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm();
    let history = useHistory();

    async function login(data) {
        const response = await GUESTPOST("/auth/login", data);
        const baseURL =
            window.location.hostname === "localhost"
                ? "https://bozormedia.uz"
                : "https://" + window.location.hostname;
        const user = await axios.get(baseURL + "/services/uaa/api/account", {
            headers: {Authorization: "Bearer " + response.access_token},
        });
        // user = JSON.parse(user.data)
        // const user = await GET('/services/uaa/api/account')
        for (let i = 0; i < user.data.authorities.length; i++) {
            if (user.data.authorities[i] === "ROLE_ADMIN") {
                data.role = "ADMIN";
                localStorage.setItem("admin_access_token", response.access_token);
                localStorage.setItem(
                    "admin_tokenTime",
                    JSON.stringify(new Date().getTime())
                );
                localStorage.setItem("admin_user", JSON.stringify(data));
                history.push("/admin/main");
            }
            if (user.data.authorities[i] === "ROLE_OPERATOR") {
                data.role = "OPERATOR";
                localStorage.setItem("operator_user", JSON.stringify(data));
                localStorage.setItem(
                    "operator_tokenTime",
                    JSON.stringify(new Date().getTime())
                );
                localStorage.setItem(
                    "operator_access_token",
                    response.access_token
                );
                history.push("/operator/orders");
            }
        }
    }

    return (
        <div className="bg-body" style={{height: '100vh'}}>
            <div className="d-flex flex-column flex-root">
                <div
                    className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
                    style={{background: `url(${img})`, height: '100vh', width: '100%', backgroundRepeat: 'no-repeat'}}>
                    <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
                        <a href="../../demo1/dist/index.html" className="mb-12">
                            <img alt="Logo" src="/img/logo/logo.png" className="h-100px"/>
                        </a>
                        <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
                            <form
                                autoComplete="off"
                                method="POST"
                                onSubmit={handleSubmit(login)}
                                className="form w-100"
                                noValidate
                                id="kt_sign_in_form"
                                action="#"
                            >
                                <div className="text-center mb-10">
                                    <h1 className="text-dark mb-3">Войти в систему</h1>
                                </div>
                                <div className="fv-row mb-10">
                                    <label className="form-label fs-6 fw-bolder text-dark">Логин</label>
                                    <input
                                        {...register("username", {
                                            required: true,
                                            minLength: 4,
                                        })}
                                        className="form-control form-control-lg form-control-solid" type="text"
                                        autoComplete="off"/>
                                    {errors.username?.type === "required" && (
                                        <div className="text-danger mt-2">
                                            Обязательное поле
                                        </div>
                                    )}
                                    {errors.username?.type === "minLength" && (
                                        <div className="text-danger mt-2">
                                            Поле должно быть не менее 4 символов
                                        </div>
                                    )}
                                </div>
                                <div className="fv-row mb-10">
                                    <div className="d-flex flex-stack mb-2">
                                        <label className="form-label fw-bolder text-dark fs-6 mb-0">Пароль</label>
                                    </div>
                                    <input
                                        {...register("password", {
                                            required: true,
                                            maxLength: 20,
                                            minLength: 4,
                                        })}
                                        className="form-control form-control-lg form-control-solid" type="password"
                                        name="password" autoComplete="off"/>
                                    {errors.password?.type === "required" && (
                                        <div className="text-danger mt-2">
                                            Обязательное поле
                                        </div>
                                    )}
                                    {errors.password?.type === "minLength" && (
                                        <div className="text-danger mt-2">
                                            Поле должно быть не менее 4 символов
                                        </div>
                                    )}
                                </div>
                                <div className="text-center">
                                    <button type="submit" id="kt_sign_in_submit"
                                            className="btn btn-lg btn-primary w-100 mb-5">
                                        <span className="indicator-label">Войти</span>
                                        <span className="indicator-progress">Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminLogin;
