import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {DebounceInput} from "react-debounce-input";
import ReactPaginate from "react-paginate";
import {PGET, GET} from "../../../api/adminApi";
import Select from "react-select";
import {useDispatch} from "react-redux";

function UserList() {
    const [items, setItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [value, setValue] = useState("");
    const [currentPage, setCurrentPage] = useState(
        +localStorage.getItem("currentPageUser") || 0
    ); //for numeration and saving currentPage after refresh
    const [regions, setRegions] = useState([{id: 0, name: "Все регионы"}]);
    const [region, setRegion] = useState(regions[0]);
    const [cities, setCities] = useState([{id: 0, name: "Все районы"}]);
    const [bazars, setBazars] = useState([{id: 0, nameRu: "Все базары"}]);
    const [shops, setShops] = useState([{id: 0, nameRu: "Все магазины"}]);
    const [city, setCity] = useState(null); // initialize with null
    const [bazar, setBazar] = useState(null); // initialize with null
    const [shop, setShop] = useState(null);
    const dispatch = useDispatch();
    const history = useHistory();
    function paginate(data) {
        setCurrentPage(data.selected);
        localStorage.setItem("currentPageUser", data.selected); //for saving currentPage after refresh
    }



    const handleSearchInput = (e) => {
        setValue(e.target.value);
        setCurrentPageToZero();
    };

    function setCurrentPageToZero() {
        setCurrentPage(0);
        localStorage.setItem("currentPageUser", 0);
    }

    const selectRegion = (selectedOpt) => {
        if (region !== selectedOpt) {
            setRegion(selectedOpt);
            setCurrentPageToZero();
        }
    };
    const selectCity = (selectedOpt) => {
        if (city !== selectedOpt) {
            setCity(selectedOpt);
            setBazar({ id: 0, nameRu: "Все базары" });
            setCurrentPageToZero();
        }
    };
    const selectBazar = (selectedOpt) => {
        if (bazar !== selectedOpt) {
            setBazar(selectedOpt);
            setCurrentPageToZero();
        }
    };
    const selectShop = (selectedOpt) => {
        if (shop !== selectedOpt) {
            setShop(selectedOpt);
            setCurrentPageToZero();
        }
    };
    async function getRegions() {
        const response = await GET("/services/admin/api/region-helper/1");

        if (response.length > 0) {
            setRegions([{ id: 0, name: "Все регионы" }, ...response]);
            setCity({ id: 0, name: "Все районы" });
            setBazar({ id: 0, nameRu: "Все базары" });
            setShop({ id: 0, nameRu: "Все магазины" });
        }
    }

    async function getCities(regionId) {
        if(regionId && regionId !== 0) {
            const response = await GET(`/services/admin/api/city-helper/${regionId}`);

            if (response.length > 0) {
                setCities([{ id: 0, name: "Все районы" }, ...response]);
            }
        }
    }

    async function getBazars(cityId) {
        if(cityId && cityId !== 0) {
            const response = await GET(`/services/admin/api/bazar-helper/${cityId}`);
            setBazars(response.length > 0 ? [{ id: 0, nameRu: "Все базары" }, ...response] : []);
        }
    }

    useEffect(() => {
        if (region.id !== 0) {
            getCities(region.id);
        }
    }, [region]);

    useEffect(() => {
        if (city && city.id !== 0) {
            getBazars(city.id);
        }
    }, [city]);

    async function getItems() {
        const data = await PGET("/services/admin/api/user-pageList", true, {
            bozorId: bazar ? bazar.id : 0,
            shopId: shop ? shop.id : 0,
            page: currentPage,
            size: 10,
            search: value,
            regionId: region ? region.id : 0,
            cityId: city ? city.id : 0,
        });
        setPageCount(Math.ceil(data.headers["x-total-count"] / 10));
        setItems(data.data);
    }
    useEffect(() => {
        getRegions();
    }, []);
    useEffect(() => {
        getItems();
    }, [currentPage, value, region, city, bazar, shop]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div
            className="br-5 card"
            style={{background: '#eef1ff', marginTop: "-0.9rem", height: '100vh'}}
        >
            <div className='card-header border-0 pt-5' style={{paddingLeft: 12}}>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Пользователи</span>
                </h3>
            </div>

            <div className="px-3 pb-4 pt-2">
                <div className="mb-4 d-flex justify-content-between">
                    <div className="position-relative">
                        <DebounceInput
                            minLength={3}
                            debounceTimeout={400}
                            value={value}
                            onChange={handleSearchInput}
                            type="text"
                            className="input-search search-icon br-5 "
                            size={24}
                            placeholder="Поиск"
                        />
                    </div>
                </div>
                <div style={{overflowX: "auto"}}>
                    <table className='table bg-white table-rounded table-striped border gy-3 gs-7'>
                        <thead>
                        <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                            <th style={{width: "30px"}} className="text-center">
                                №
                            </th>
                            <th className="text-start">Логин</th>
                            <th className="text-start">Имя</th>
                            <th className="text-start">Пол</th>
                            <th className="text-start">Телефон номер</th>
                            <th className="text-center">Баланс</th>
                        </tr>
                        </thead>

                        <tbody style={{overflowX: "auto"}}>
                        {items.map((item, index) => {
                            return (
                                <tr key={item.id}>
                                    <td className="text-center">
                                        {currentPage === 0
                                            ? index + 1
                                            : currentPage * 10 + index + 1}
                                    </td>
                                    <td className="text-start">{item.login ? item.login : '-'}</td>
                                    <td className="text-start">
                                        <div style={{width: "100%", height: "40px", display: 'flex', alignItems: 'center', }}>
                                            <img src={item.imageUrl ? item.imageUrl : "https://www.pulsecarshalton.co.uk/wp-content/uploads/2016/08/jk-placeholder-image.jpg"} alt="photo" style={{width: "30px", height: "30px", borderRadius: "50%"}} />
                                            <p style={{marginLeft: 10}}>
                                                {item.firstName ? item.firstName : '-'} {item.lastName ? item.lastName : ''}
                                            </p>
                                        </div>
                                    </td>
                                    <td className="text-start">{item.gender === 1 && 'Мужчина' || item.gender === 2 && 'Женщина' || item.gender === 0 && '-' }</td>
                                    <td className="text-start">{item.phone ? item.phone : '-'}</td>
                                    <td className="text-center">{item.balance}</td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
                {pageCount > 1 && (
                    <ReactPaginate
                        forcePage={currentPage}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        onPageChange={paginate}
                        containerClassName={"pagination"}
                        activeClassName={"bg-dark text-white"}
                        pageClassName={"page-item box-shadow"}
                    />
                )}
            </div>
        </div>
    );
}

export default UserList;
