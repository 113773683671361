import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { DebounceInput } from 'react-debounce-input';
import ReactPaginate from 'react-paginate';
import { Search } from 'react-feather';

import { DELETE, PGET } from '../../../api/adminApi'
import {CustomSvg} from "../../../utils/CustomSvg";
import {useSelector} from "react-redux";

function ShopOptions() {
	const [items, setItems] = useState([])
	const [pageCount, setPageCount] = useState(0)
	const magazinId = useSelector((state) => state.magazin);

	async function getItems() {
		const data = await PGET('/services/admin/api/shop/option-pageList', true, { shopId: magazinId, page: 0, size: 20 });
		setPageCount(Math.ceil(data.headers['x-total-count'] / 20))
		setItems(data.data)
	}
	async function deleteItem(id) {
		await DELETE('/services/admin/api/shop/option/' + id)
		getItems()
	}

	async function search(e) {
		const data = await PGET('/services/admin/api/option-pageList?search=' + e.target.value, true)
		setPageCount(Math.ceil(data.headers['x-total-count'] / 20))
		setItems(data.data);
	};

	async function paginate(data) {
		const response = await PGET('/services/admin/api/option-pageList', true, { page: data.selected, size: 20 })
		setItems(response.data);
	};

	useEffect(() => {
		getItems()
	}, [])

	return (
		<>
			<div
				className="br-5 card"
				style={{background: '#eef1ff', marginTop: "-0.9rem", height: '100vh'}}
			>
				<div className='card-header border-0 pt-5'>
					<h3 className='card-title align-items-start flex-column'>
						<span className='card-label fw-bold fs-3 mb-1'>Опции магазина</span>
					</h3>
				</div>
				<div className='px-3 pb-4 pt-2'>
					<div className="mb-4 d-flex justify-content-between">
						<div className="position-relative">
							<Search size={14} color='#9D9BA3' className="input-search-icon" />
							<DebounceInput minLength={3} debounceTimeout={400} onChange={(e) => search(e)} type="text" className="input-search" size={24} placeholder='Поиск' />
						</div>
						<div
							className='card-toolbar'
							data-bs-toggle='tooltip'
							data-bs-placement='top'
							data-bs-trigger='hover'
							title='Click to add a user'
						>

							<Link
								to="/admin/shop/options/create"
								className='btn btn-sm btn-light-primary'
								data-bs-toggle='modal'
								data-bs-target='#kt_modal_invite_friends'
							>
								<CustomSvg path='/icons/duotune/arrows/arr075.svg' className='svg-icon-3'/>
								Добавить новый
							</Link>
						</div>
					</div>
					<div style={{ overflowX: 'auto' }}>
						<table className='table bg-white table-rounded table-striped border gy-3 gs-7'>
							<thead>
							<tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
									<th className="text-start" >Название </th>
									<th className="text-start" >Значение</th>
									<th className="text-center">Действие </th>
								</tr>
							</thead>

							<tbody style={{ overflowX: 'auto' }}>
								{items.map((item) => {
									return (
										<tr key={item.id}>
											<td className="text-start" >{item.optionName}</td>
											<td className="text-start">{item.optionValue}</td>
											<td className="text-start">
												<div className="d-flex justify-content-center">
													<button
														onClick={() => deleteItem(item.id)}
														className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
													>
														<CustomSvg
															path='/icons/duotune/general/gen027.svg'
															className='svg-icon-3'
														/>
													</button>
												</div>
											</td>
										</tr>
									)
								})
								}
							</tbody>
						</table>
					</div>
					{
						pageCount > 1 &&
						<ReactPaginate
							// breakLabel={'...'}
							// breakClassName={'break-me'}
							pageCount={pageCount}
							marginPagesDisplayed={2}
							pageRangeDisplayed={5}
							onPageChange={paginate}
							containerClassName={'pagination'}
							activeClassName={'active'}
							pageClassName={'page-item'}
						/>
					}
				</div>
			</div>
		</>
	)
}

export default ShopOptions
