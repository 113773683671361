import axios from "axios";
import { showLoader, hideLoader } from "../store/actions/loader";
import { store } from "../store";
import { toast } from "react-toastify";

const baseURL =
   window.location.hostname === "localhost"
      ? "https://bozormedia.uz"
      : "https://" + window.location.hostname;

const axiosClient = axios.create({
   baseURL: baseURL,
   withCredentials: true,
   headers: {
      "Content-Type": "application/json",
      accept: "application/json",
   },
});
const axiosFile = axios.create({
   baseURL: baseURL,
   // timeout: 2000,
   withCredentials: true,
   headers: {
      Authorization: "Bearer " + localStorage.getItem("admin_access_token"),
      "Content-Type": "multipart/form-data",
      accept: "multipart/form-data",
   },
});

async function checkToken() {
   var tokenTime = localStorage.getItem("admin_tokenTime");
   var difference = Math.floor((Date.now() - tokenTime) / 1000 / 60);
   if (difference < 4) {
      return;
   } else {
      const user = localStorage.getItem("admin_user");
      await axiosClient.post("/auth/login", user).then((response) => {
         localStorage.setItem("admin_access_token", response.data.access_token);
         localStorage.setItem(
            "admin_tokenTime",
            JSON.stringify(new Date().getTime())
         );
      });
      return;
   }
}

export async function PGET(URL, loader = true, payload = {}) {
   var params = "";
   if (Object.entries(payload).length > 0) {
      params = getPath(payload);
   }
   if (loader) {
      store.dispatch(showLoader());
   }
   await checkToken();
   const data = await axiosClient
      .get(`${URL}` + params, {
         headers: {
            Authorization:
               "Bearer " + localStorage.getItem("admin_access_token"),
         },
      })
      .catch((error) => httpStatusChecker(error));
   if (loader) {
      store.dispatch(hideLoader());
   }
   return data;
}
// air
export async function GET(URL, loader = true) {
   if (loader) {
      store.dispatch(showLoader());
   }
   await checkToken();
   const data = await axiosClient
      .get(`${URL}`, {
         headers: {
            Authorization:
               "Bearer " + localStorage.getItem("admin_access_token"),
         },
      })
      .catch((error) => httpStatusChecker(error));
   if (loader) {
      store.dispatch(hideLoader());
   }
   return data?.data;
}

export async function POST(URL, payload, loader = true) {
   let jsonData = JSON.stringify(payload);
   if (loader) {
      store.dispatch(showLoader());
      await checkToken();
      const data = await axiosClient
         .post(`${URL}`, jsonData, {
            headers: {
               Authorization:
                  "Bearer " + localStorage.getItem("admin_access_token"),
            },
         })
         .then((response) => response.data)
         .catch((error) => {
            httpStatusChecker(error);
            return error;
         });
      store.dispatch(hideLoader());
      return data;
   } else {
      await checkToken();
      return axiosClient
         .post(`${URL}`, jsonData, {
            headers: {
               Authorization:
                  "Bearer " + localStorage.getItem("admin_access_token"),
            },
         })
         .then((response) => response.data);
   }
}
export async function POSTNEW(URL, payload, loader = true) {
   let jsonData = JSON.stringify(payload);
   if (loader) {
      store.dispatch(showLoader());
      await checkToken();
      var data = await axiosClient
          .post(`${URL}`, jsonData, {
             headers: {
                Authorization:
                    "Bearer " + localStorage.getItem("admin_access_token"),
             },
          })
          .then((response) => response)
          .catch((error) => httpStatusChecker(error));
      store.dispatch(hideLoader());
      if (data && data.status === 200) {
         toast.success("Успешно");
      }
      return data?.data;
   } else {
      await checkToken();
      return axiosClient
          .post(`${URL}`, jsonData)
          .then((response) => response.data);
   }
}
export async function PUT(URL, payload, loader = true) {
   let jsonData = JSON.stringify(payload);
   if (loader) {
      store.dispatch(showLoader());
      await checkToken();
      var data = await axiosClient
         .put(`${URL}`, jsonData, {
            headers: {
               Authorization:
                  "Bearer " + localStorage.getItem("admin_access_token"),
            },
         })
         .then((response) => response)
         .catch((error) => httpStatusChecker(error));
      store.dispatch(hideLoader());
      if (data && data.status === 200) {
         toast.success("Успешно");
      }
      return data?.data;
   } else {
      await checkToken();
      return axiosClient
         .put(`${URL}`, jsonData)
         .then((response) => response.data);
   }
}

export async function FILE(URL, payload, loader = true) {
   await checkToken();
   if (loader) {
      store.dispatch(showLoader());
      const data = await axiosFile
         .post(`${URL}`, payload, {
            headers: {
               Authorization:
                  localStorage.getItem("admin_access_token") === null
                     ? ""
                     : "Bearer " + localStorage.getItem("admin_access_token"),
            },
         })
         .then((response) => response)
         .catch((error) => httpStatusChecker(error))
         .catch((error) => {
            store.dispatch(hideLoader());
            toast.error("Ошибка" + error);
         });
      store.dispatch(hideLoader());
      return data.data;
   } else {
      return axiosFile
         .post(`${URL}`, payload, {
            headers: {
               "Content-Type": "multipart/form-data",
               Authorization:
                  localStorage.getItem("admin_access_token") === null
                     ? ""
                     : "Bearer " + localStorage.getItem("admin_access_token"),
            },
         })
         .then((response) => response.data)
         .catch((error) => httpStatusChecker(error));
   }
}

export async function GUESTGET(URL, loader = true) {
   if (loader) {
      store.dispatch(showLoader());
      const data = await axiosClient
         .get(`${URL}`)
         .then((response) => response.data)
         .catch((error) => httpStatusChecker(error));
      store.dispatch(hideLoader());
      return data;
   } else
      return axiosClient
         .get(`${URL}`)
         .then((response) => response.data)
         .catch((error) => httpStatusChecker(error));
}

export async function GUESTPOST(URL, payload, loader = true) {
   let jsonData = JSON.stringify(payload);
   if (loader) {
      store.dispatch(showLoader());
      const data = await axiosClient
         .post(`${URL}`, jsonData)
         .then((response) => response.data)
         .catch((error) => httpStatusChecker(error));
      store.dispatch(hideLoader());
      return data;
   } else {
      return axiosClient
         .post(`${URL}`, jsonData)
         .then((response) => response.data)
         .catch((error) => httpStatusChecker(error));
   }
}

export async function DELETE(URL, loader = true) {
   await checkToken();
   if (loader) {
      store.dispatch(showLoader());
      const data = await axiosClient
         .delete(`${URL}`, {
            headers: {
               Authorization:
                  "Bearer " + localStorage.getItem("admin_access_token"),
            },
         })
         .then((response) => response.data)
         .catch((error) => httpStatusChecker(error));
      store.dispatch(hideLoader());
      return data;
   } else {
      return await axiosClient
         .delete(`${URL}`, {
            headers: {
               Authorization:
                  "Bearer " + localStorage.getItem("admin_access_token"),
            },
         })
         .then((response) => response.data)
         .catch((error) => httpStatusChecker(error));
   }
}
export async function DOWNLOAD(URL, fileName, loader = true) {
   if (loader) {
      store.dispatch(showLoader());
   }
   await checkToken();
   const response = await axios({
      method: 'get',
      url: URL,
      responseType: 'blob',
      headers: {
         Authorization: 'Bearer ' + localStorage.getItem('admin_access_token'),
      },
   }).catch((error) => httpStatusChecker(error));
   if (loader) {
      store.dispatch(hideLoader());
   }
   const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
   const link = document.createElement('a');
   link.href = url;
   link.setAttribute('download', fileName);
   document.body.appendChild(link);
   link.click();
   document.body.removeChild(link);
}
function getPath(payload, url) {
   let iterations = Object.entries(payload).length;
   var pathArr = "?";
   if (url) url.includes("?") ? (pathArr = "&") : (pathArr = "?");

   for (let key in payload) {
      if (!--iterations) {
         pathArr += key + "=" + payload[key];
      } else {
         pathArr += key + "=" + payload[key] + "&";
      }
   }
   return pathArr;
}

function httpStatusChecker(error) {
   // console.log(error);
   // console.log(error.response.status === 401);
   if (!error.response) {
      toast.error("Ошибка: Нет подключение к интернету");
      return;
   }
   if (error.response.status === 400) {
      toast.error(error.response.data.message);
      return;
   }
   if (error.response.status === 401) {
      checkToken();
      toast.error("Ошибка: Неверный логин или пароль");
      return;
   }
   if (error.response.status === 404) {
      toast.error("Ошибка: Не найдено");
      return;
   }
   if (error.response.status === 415) {
      toast.error("Ошибка: Не поддерживаемый тип");
      return;
   }
   if (error.response.status === 500) {
      toast.error("Системная ошибка:" + error.response.data.message);
      return;
   }
}
