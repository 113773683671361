import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {DebounceInput} from "react-debounce-input";
import ReactPaginate from "react-paginate";
import {PGET, DELETE, GET} from "../../../api/adminApi";
import {CustomSvg} from "../../../utils/CustomSvg";
import Select from "react-select";
import {useDispatch} from "react-redux";
import {setShopId} from "../../../store/actions/shop";

function Products() {
    const [items, setItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [value, setValue] = useState("");
    const [currentPage, setCurrentPage] = useState(
        +localStorage.getItem("currentPageProduct") || 0
    ); //for numeration and saving currentPage after refresh
    const [regions, setRegions] = useState([{id: 0, name: "Все регионы"}]);
    const [region, setRegion] = useState(regions[0]);
    const [cities, setCities] = useState([{id: 0, name: "Все районы"}]);
    const [bazars, setBazars] = useState([{id: 0, nameRu: "Все базары"}]);
    const [shops, setShops] = useState([{id: 0, nameRu: "Все магазины"}]);
    const [accessOptions, setAccessOptions] = useState([{id: 0, name: "Статус"}, {id: 1, name: "Подтвержден"}, {id: 2, name: "Не подтвержден"}])
    const [city, setCity] = useState(null); // initialize with null
    const [bazar, setBazar] = useState(null); // initialize with null
    const [shop, setShop] = useState(null);
    const [access, setAccess] = useState(accessOptions[0]);
    const dispatch = useDispatch();
    const history = useHistory();
    function paginate(data) {
        setCurrentPage(data.selected);
        localStorage.setItem("currentPageProduct", data.selected); //for saving currentPage after refresh
    }


    const handleSearchInput = (e) => {
        setValue(e.target.value);
        setCurrentPageToZero();
    };

    function setCurrentPageToZero() {
        setCurrentPage(0);
        localStorage.setItem("currentPageProduct", 0);
    }

    const selectRegion = (selectedOpt) => {
        if (region !== selectedOpt) {
            setRegion(selectedOpt);
            setCurrentPageToZero();
        }
    };
    const selectStatus = (selectedOpt) => {
        if (access !== selectedOpt) {
            setAccess(selectedOpt)
            setCurrentPageToZero();
            
        }
    }
    const selectCity = (selectedOpt) => {
        if (city !== selectedOpt) {
            setCity(selectedOpt);
            setBazar({ id: 0, nameRu: "Все базары" });
            setCurrentPageToZero();
        }
    };
    const selectBazar = (selectedOpt) => {
        if (bazar !== selectedOpt) {
            setBazar(selectedOpt);
            setCurrentPageToZero();
        }
    };
    const selectShop = (selectedOpt) => {
        if (shop !== selectedOpt) {
            setShop(selectedOpt);
            setCurrentPageToZero();
        }
    };

    useEffect(() => {
        getRegions();
    }, []);

    async function getRegions() {
        const response = await GET("/services/admin/api/region-helper/1");

        if (response.length > 0) {
            setRegions([{ id: 0, name: "Все регионы" }, ...response]);
            setCity({ id: 0, name: "Все районы" });
            setBazar({ id: 0, nameRu: "Все базары" });
            setShop({ id: 0, nameRu: "Все магазины" });
        }
    }

    async function getCities(regionId) {
        if(regionId && regionId !== 0) {
            const response = await GET(`/services/admin/api/city-helper/${regionId}`);

            if (response.length > 0) {
                setCities([{ id: 0, name: "Все районы" }, ...response]);
            }
        }
    }

    async function getBazars(cityId) {
        if(cityId && cityId !== 0) {
            const response = await GET(`/services/admin/api/bazar-helper/${cityId}`);
            setBazars(response.length > 0 ? [{ id: 0, nameRu: "Все базары" }, ...response] : []);
        }
    }

    useEffect(() => {
        if (region.id !== 0) {
            getCities(region.id);
        }
    }, [region]);

    useEffect(() => {
        if (city && city.id !== 0) {
            getBazars(city.id);
        }
    }, [city]);

    async function getItems() {
        const data = await PGET("/services/admin/api/product-pageList", true, {
            bozorId: bazar ? bazar.id : 0,
            shopId: shop ? shop.id : 0,
            page: currentPage,
            size: 10,
            search: value,
            regionId: region ? region.id : 0,
            cityId: city ? city.id : 0,
            approved: access ? access.id : 0
        });
        setPageCount(Math.ceil(data.headers["x-total-count"] / 10));
        setItems(data.data);
    }

    async function deleteItem(id) {
        await DELETE("/services/admin/api/product/" + id);
        getItems();
    }

    useEffect(() => {
        getItems();
    }, [currentPage, value, region, city, bazar, shop, access]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div
            className="br-5 card"
            style={{background: '#eef1ff', marginTop: "-0.9rem", height: '100vh'}}
        >
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Товары</span>
                </h3>

                <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to add a user'
                >

                    <Link
                        to="/admin/product/create"
                        className='btn btn-sm btn-light-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_invite_friends'
                    >
                        <CustomSvg path='/icons/duotune/arrows/arr075.svg' className='svg-icon-3'/>
                        Добавить новый
                    </Link>
                </div>
            </div>

            <div className="px-3 pb-4 pt-2">
                <div className="mb-4 d-flex justify-content-between">
                    <div className='d-flex'>
                        <div style={{ width: '150px', marginRight: 30 }}>
                            <Select
                              value={region}
                              placeholder='Выберите регион'
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              options={regions}
                              onChange={selectRegion}
                            />
                        </div>
                        <div style={{ width: '150px', marginRight: 30 }}>
                            <Select
                              value={access}
                              placeholder='Выберите статус'
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              options={accessOptions}
                              onChange={selectStatus}
                            />
                        </div>
                        <div style={{ width: '150px', marginRight: 30 }}>
                            <Select
                              value={city}
                              placeholder='Выберите районы'
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              options={cities}
                              onChange={selectCity}
                            />
                        </div>
                        <div style={{ width: '150px', marginRight: 30 }}>
                            <Select
                              value={bazar}
                              placeholder='Выберите базар'
                              getOptionLabel={(option) => option.nameRu}
                              getOptionValue={(option) => option.id}
                              options={bazars}
                              onChange={selectBazar}
                            />
                        </div>
                    
                    </div>
                    <div className='position-relative'>
                        <DebounceInput
                          minLength={3}
                          debounceTimeout={400}
                          value={value}
                          onChange={handleSearchInput}
                          type='text'
                          className='input-search search-icon br-5 '
                          size={24}
                          placeholder='Поиск'
                        />
                    </div>
                </div>
                <div style={{overflowX: "auto"}}>
                    <table className='table bg-white table-rounded table-striped border gy-3 gs-7'>
                        <thead>
                        <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                            <th style={{width: "30px"}} className="text-center">
                                №
                            </th>
                            <th className="text-start">Название</th>
                            <th className="text-start">Старая цена</th>
                            <th className="text-start">Новая цена</th>
                            <th className="text-start">Магазин</th>
                            <th className="text-start">Базар</th>
                            <th className="text-start">Город/Район</th>
                            <th className="text-center">Статус</th>
                            <th className="text-center">Действия</th>
                        </tr>
                        </thead>

                        <tbody style={{overflowX: "auto"}}>
                        {items.map((item, index) => {
                            return (
                                <tr key={item.id}>
                                    <td className="text-center">
                                        {currentPage === 0
                                            ? index + 1
                                            : currentPage * 10 + index + 1}
                                    </td>
                                    <td className="text-start">{item.nameRu}</td>
                                    <td className="text-start">{item.oldPrice} сум</td>
                                    <td className="text-start">{item.newPrice} сум</td>
                                    <td className="text-start">{item.shopNameRu}</td>
                                    <td className="text-start">{item.bozorNameRu}</td>
                                    <td className="text-start">
                                        <div style={{display: 'flex', flexDirection: 'column'}}>
                                            <p style={{margin: 0}}>{item.regionNameRu}</p>
                                            <p>{item.cityNameRu}</p>
                                        </div>
                                    </td>
                                    <td
                                        className='text-center'
                                    >
                                        {item.accessed ? (
                                            <span className='badge badge-light-success fs-7 fw-bold'>Подтвержден</span>
                                        ) : (<span className='badge badge-light-danger fs-7 fw-bold'>Не подтвержден</span>
                                        )}
                                    </td>
                                    <td>
                                        <div className="d-flex justify-content-center">
                                            <Link
                                                to={"/admin/product/update/" + item.id}
                                                href='#'
                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                            >
                                                <CustomSvg path='/icons/duotune/art/art005.svg' className='svg-icon-3'/>
                                            </Link>
                                            <button
                                                onClick={() => deleteItem(item.id)}
                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                            >
                                                <CustomSvg
                                                    path='/icons/duotune/general/gen027.svg'
                                                    className='svg-icon-3'
                                                />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
                {pageCount > 1 && (
                    <ReactPaginate
                        forcePage={currentPage}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        onPageChange={paginate}
                        containerClassName={"pagination"}
                        activeClassName={"bg-dark text-white"}
                        pageClassName={"page-item box-shadow"}
                    />
                )}
            </div>
        </div>
    );
}

export default Products;
