import React, { useState, useEffect } from 'react'
import { Link, useParams, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { XCircle, Save } from 'react-feather';
import { POST, GET } from '../../../api/adminApi'
import '../../../css/antd.css';
import {Button} from "react-bootstrap";
import {useSelector} from "react-redux";

function ShopCategoriesCrud() {
    let history = useHistory();
    const magazinId = useSelector((state) => state.magazinId);
    const [categories, setCategories] = useState([{ id: 0, parentId: null, name: 'Нет' }]);
    const { register, handleSubmit, formState: { errors }, setValue } = useForm()
    const [optionId, setOptionId] = useState('');

    async function getCategories() {
        const response = await GET("/services/admin/api/categories-helper", false);
        setCategories(response);
    }
    async function create(data) {
        let response;
        data.shopId = magazinId;
        response = await POST('/services/admin/api/shop/category', data)
        if (response) {
            history.push('/admin/shop/category', response)
        }
    }
    useEffect(() => {
        if (categories.length > 0) {
            setOptionId(categories[0].id); // Set the first element's id as the default value
            setValue("optionId", categories[0].id); // Set the value in the form context
        }
    }, [categories]);

    useEffect(() => {
        getCategories()

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="mb-2 mx-3 ">
            <h3 className="mb-3 mt-1">Добавить новый</h3>
            <div className="admin-card p-4 overflow-y-auto" style={{ height: "80vh" }} >
                <form onSubmit={handleSubmit(create)} action="" autoComplete='off'>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Категории</label>
                                    </div>
                                    <select
                                        value={optionId}
                                        className="admin-select"
                                        {...register("categoryId", {required: true})}
                                        onChange={(e) => {
                                            setOptionId(e.target.value);
                                            setValue("categoryId", e.target.value);
                                        }}
                                    >
                                        {categories.map((item) => {
                                            return (
                                                <option key={item.id} value={item.id}>
                                                    {item.name}{" "}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                {errors.regionId?.type === "required" && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="btn-postion d-flex">
                        <Link to='/admin/shop/options' className="mr-3"><Button className="btn bg-dark d-flex align-items-center"><XCircle size={14} className="mr-2" />Отмена </Button></Link>
                        <Button type='submit' className="btn bg-primary d-flex align-items-center"><Save size={14} className="mr-2" />Сохранить </Button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ShopCategoriesCrud
