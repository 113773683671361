import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {DebounceInput} from "react-debounce-input";
import ReactPaginate from "react-paginate";
import {PGET, DELETE, GET} from "../../../api/adminApi";
import {CustomSvg} from "../../../utils/CustomSvg";
import Select from "react-select";
import {useDispatch} from "react-redux";
import {setShopId} from "../../../store/actions/shop";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBagShopping, faPlus} from "@fortawesome/free-solid-svg-icons";

function Bazar() {
    const [items, setItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [value, setValue] = useState("");
    const [currentPage, setCurrentPage] = useState(
        +localStorage.getItem("currentPageBazars") || 0
    ); //for numeration and saving currentPage after refresh
    const [regions, setRegions] = useState([{id: 0, name: "Все регионы"}]);
    const [region, setRegion] = useState(regions[0]);
    const [cities, setCities] = useState([{id: 0, name: "Все районы"}]);
    const [city, setCity] = useState(regions[0]);
    const dispatch = useDispatch();
    const history = useHistory();
    function paginate(data) {
        setCurrentPage(data.selected);
        localStorage.setItem("currentPageBazars", data.selected); //for saving currentPage after refresh
    }

    const handleSearchInput = (e) => {
        setValue(e.target.value);
        setCurrentPageToZero();
    };

    function setCurrentPageToZero() {
        setCurrentPage(0);
        localStorage.setItem("currentPageBazars", 0);
    }

    const selectRegion = (selectedOpt) => {
        if (region !== selectedOpt) {
            setRegion(selectedOpt);
            setCurrentPageToZero();
        }
    };
    const selectCity = (selectedOpt) => {
        if (city !== selectedOpt) {
            setCity(selectedOpt);
            setCurrentPageToZero();
        }
    };

    async function getRegions() {
        const response = await GET("/services/admin/api/region-helper/1");

        if (response.length > 0) {
            setRegions([regions[0], ...response]);
        }
    }

    async function getCities() {
        const response = await GET("/services/admin/api/city-helper/1");

        if (response.length > 0) {
            setCities([cities[0], ...response]);
        }
    }
    async function getItems() {
        const data = await PGET("/services/admin/api/bazar-pageList", true, {
            page: currentPage,
            size: 10,
            search: value,
            regionId: region.id,
            cityId: city.id,
        });
        setPageCount(Math.ceil(data.headers["x-total-count"] / 10));
        setItems(data.data);
    }

    async function deleteItem(id) {
        await DELETE("/services/admin/api/bazar/" + id);
        getItems();
    }

    const handleClick = (shopId) => {
        dispatch(setShopId(shopId));
        history.push('/admin/bazar/options');
    };
    const categoryList = (shopId) => {
        dispatch(setShopId(shopId));
        history.push('/admin/bazar/category');
    };
    useEffect(() => {
        getRegions();
        getCities();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        getItems();
    }, [currentPage, value, region, city]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div
            className="br-5 card"
            style={{background: '#eef1ff', marginTop: "-0.9rem", height: '100vh'}}
        >
            <div className='card-header border-0 pt-5' style={{paddingLeft: 12}}>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Базары</span>
                </h3>

                <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to add a user'
                >

                    <Link
                        to="/admin/bazar/create"
                        style={{backgroundColor: "#009ef7", color: "#fcfcfc"}}
                        className='btn btn-sm btn-light-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_invite_friends'
                    >
                         <FontAwesomeIcon icon={faPlus} size="lg" style={{width: 14, height: 14, marginRight: 5}}/>
                        Добавить новый
                    </Link>
                </div>
            </div>

            <div className="px-3 pb-4 pt-2">
                <div className="mb-4 d-flex justify-content-between">
                    <div className="d-flex">
                        <div style={{width: "200px", marginRight: 30}}>
                            <Select
                                value={region}
                                placeholder="Выберите регион"
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                options={regions}
                                onChange={selectRegion}
                            />
                        </div>
                        <div style={{width: "200px"}}>
                            <Select
                                value={city}
                                placeholder="Выберите районы"
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                options={cities}
                                onChange={selectCity}
                            />
                        </div>
                    </div>
                    <div className="position-relative">
                        <DebounceInput
                            minLength={3}
                            debounceTimeout={400}
                            value={value}
                            onChange={handleSearchInput}
                            type="text"
                            className="input-search search-icon br-5 "
                            size={24}
                            placeholder="Поиск"
                        />
                    </div>
                </div>
                <div style={{overflowX: "auto"}}>
                    <table className='table bg-white table-rounded table-striped border gy-3 gs-7'>
                        <thead>
                        <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                            <th style={{width: "30px"}} className="text-center">
                                №
                            </th>
                            <th className="text-start">Название базара</th>
                            <th className="text-start">Регион</th>
                            <th className="text-start">Город/Район</th>
                            <th className="text-center">Дополнительно</th>
                            <th className="text-center">Действия</th>
                        </tr>
                        </thead>

                        <tbody style={{overflowX: "auto"}}>
                        {items.map((item, index) => {
                            return (
                                <tr key={item.id}>
                                    <td className="text-center">
                                        {currentPage === 0
                                            ? index + 1
                                            : currentPage * 10 + index + 1}
                                    </td>
                                    <td className="text-start">{item.nameRu}</td>
                                    <td className="text-start">{item.regionNameRu}</td>
                                    <td className="text-start">{item.cityNameRu}</td>
                                    <td>
                                        <div className="d-flex justify-content-center">
                                            <button
                                                onClick={() => handleClick(item.id)}
                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                            >
                                                <CustomSvg  path='/icons/duotune/general/gen019.svg' className='svg-icon-3'/>

                                            </button>
                                            <button
                                                onClick={() => categoryList(item.id)}
                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                            >
                                                <CustomSvg  path='/icons/duotune/files/fil001.svg' className='svg-icon-3'/>
                                            </button>
                                            <Link
                                                to={"/admin/bazar/shops/" + item.id}
                                                href='#'
                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                            >
                                                <CustomSvg path='/icons/duotune/ecommerce/ecm004.svg' className='svg-icon-3'/>
                                            </Link>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex justify-content-center">
                                            <Link
                                                to={"/admin/bazar/update/" + item.id}
                                                href='#'
                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                            >
                                                <CustomSvg path='/icons/duotune/art/art005.svg' className='svg-icon-3'/>
                                            </Link>
                                            <button
                                                onClick={() => deleteItem(item.id)}
                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                            >
                                                <CustomSvg
                                                    path='/icons/duotune/general/gen027.svg'
                                                    className='svg-icon-3'
                                                />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
                {pageCount > 1 && (
                    <ReactPaginate
                        forcePage={currentPage}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        onPageChange={paginate}
                        containerClassName={"pagination"}
                        activeClassName={"bg-dark text-white"}
                        pageClassName={"page-item box-shadow"}
                    />
                )}
            </div>
        </div>
    );
}

export default Bazar;
