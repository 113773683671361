import React, {useState, useEffect, useRef} from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import {XCircle, Save} from "react-feather";
import {useForm} from "react-hook-form";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPen, faStar, faTrash} from '@fortawesome/free-solid-svg-icons';
import {DELETE, FILE, GET, POST, PUT} from "../../../api/adminApi";
import {showLoader, hideLoader} from "../../../store/actions/loader";
import {store} from "../../../store";
import Fileinput from "../../common/Fileinput";
import {Button} from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import {Calendar, Dropdown, TreeSelect} from "antd";
import Select from "react-select";

const TreeNode = TreeSelect.TreeNode;

function ProductsCrud() {
    let {id} = useParams();
    let history = useHistory();
    const inputRef = useRef();
    const {
        register,
        handleSubmit,
        formState: {errors},
        setValue,
        getValues,
    } = useForm({
        regionHelperDTO: {
            id: 0,
            name: "",
        },
        cityHelperDTO: {
            id: 0,
            name: "",
        },
        activated: true,
        brandTypeIdList: [],
        cityId: 0,
        companyId: 0,
        countryId: 0,
        regionId: 0,
        shopId: 0,
        nameUz: "",
        nameRu: "",
        nameEn: "",
        descriptionUz: "",
        descriptionRu: "",
        descriptionEn: "",
        categoryId: "",
        estimatedYear: "",
        contactPhone: "",
        productCreateImageList: "",
    });
    const [regions, setRegions] = useState([]);
    const [cities, setCities] = useState([]);
    const [data, setData] = useState({
        imageUrl: "",
        iconImageUrl: "",
        activated: true,
    });
    const [resData, setResData] = useState();
    const [pickedDate, setPickedDate] = useState(new Date());
    const [publishDate, setPublishDate] = useState(new Date());
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [imageUrls, setImageUrls] = useState([]);
    const [imageUrlError, setImageUrlError] = useState(false);
    const lastDateOfYear = `12/31/${new Date().getFullYear()}`;
    const [shops, setShops] = useState([]);
    const [categories, setCategories] = useState([{name: 'Нет', parentId: 0, id: 0}])
    const [category, setCategory] = useState()
    const [bazars, setBazars] = useState([]);
    const [show, setShow] = useState(false)


    const onChange = (string) => {
        setCategory(string)
        setValue('categoryId', string)
    };

    const handleFileChange = async (e) => {
        const files = e.target.files;
        const filesArray = Array.from(files).map((file) => file);
        setSelectedFiles([...selectedFiles, ...filesArray]);

        const uploadedUrls = await uploadFiles(filesArray);
        setImageUrls([...imageUrls, ...uploadedUrls]);
    };

    const uploadFiles = async (files) => {
        try {
            const uploadedUrls = [];
            const formData = new FormData();
            files.forEach((file) => {
                formData.append("image", file);
            });
            const response = await FILE("/services/admin/api/product-image-upload", formData);
            const mainImage = response.url;

            uploadedUrls.push(mainImage);
            return uploadedUrls;
        } catch (error) {
            console.error("Error uploading files:", error);
            return [];
        }
    };

    async function getProduct() {
        const response = await GET("/services/admin/api/product/" + id, false);
        setResData(response);
        for (let key in response) {
            setValue(`${key}`, response[key]);
        }
        onChange(response.categoryId)
        getRegions();
        setData({
            ...data
        });
    }

    async function getRegions() {
        const response = await GET("/services/admin/api/region-helper/1", false);
        const formattedRegions = response.map(({id, name}) => ({value: id, label: name}));
        setRegions(formattedRegions);
        if (!id) {
            setValue("regionId", formattedRegions[0].value);
            getCities(formattedRegions[0].value);
        } else {
            getCities(getValues("regionId"));
            setValue("regionId", getValues("regionId"));
        }
    }

    async function getCities(regionId) {
        const response = await GET("/services/admin/api/city-helper/" + regionId, false);
        const formattedCities = response.map(({id, name}) => ({value: id, label: name}));
        setCities(response.length > 0 ? formattedCities : []);

        if (!id) {
            setValue("cityId", response.length > 0 ? formattedCities[0].value : null);
        } else {
            getBazars(getValues("cityId"));
            setValue("cityId", getValues("cityId"));
        }
    }

    async function getBazars(cityId) {
        if (cityId) {
            const response = await GET("/services/admin/api/bazar-helper/" + cityId, false);
            const formattedBazars = response.map(({id, nameRu}) => ({value: id, label: nameRu}));
            setBazars(response.length > 0 ? formattedBazars : []);
            if (!id) {
                setValue("bozorId", response.length > 0 ? formattedBazars[0].value : null);
            } else {
                getShops(getValues("bozorId"));
                setValue("bozorId", getValues("bozorId"));
            }
        }
    }

    async function getShops(bazarId) {
        if (bazarId) {
            const response = await GET("/services/admin/api/shop-helper/" + bazarId, false);
            const formattedShops = response.map(({id, name}) => ({value: id, label: name}));
            setShops(formattedShops);
        } else {
            setShops([]);  // Clear the shops if no bazar is selected
        }
    }

    function sortCategories(arr) {
        var tree = [{name: 'Нет', id: 0, children: []}],
            treeChild = []
        for (var i = 0, len = arr.length; i < len; i++) {
            if (arr[i].parentId === 0) {
                arr[i]['children'] = []
                tree.push(arr[i]);
            } else {
                // arr[i]['children'] = []
                treeChild.push(arr[i])
            }
        }
        for (let i = 0; i < treeChild.length; i++) {
            for (let j = 0; j < treeChild.length; j++) {
                if (treeChild[i].id === treeChild[j].parentId) {
                    treeChild[i]['children'] = []
                }
            }
        }

        for (let i = 0; i < treeChild.length; i++) {
            for (let j = 0; j < treeChild.length; j++) {
                if (treeChild[i].id === treeChild[j].parentId) {
                    treeChild[i]['children'].push(treeChild[j])
                }
            }
        }

        for (let i = 0; i < tree.length; i++) {
            for (let j = 0; j < treeChild.length; j++) {
                if (tree[i]['id'] === treeChild[j]['parentId']) {
                    tree[i]['children'].push(treeChild[j])
                }
            }
        }
        setCategories(tree)
        setShow(true)
        return tree;
    }

    async function getCategories() {
        const categories = await GET('/services/admin/api/categories-helper')
        sortCategories(categories)
    }

    async function create(data) {
        data.expireDate = pickedDate.toISOString().split('T')[0];
        data.publishDate = publishDate.toISOString().split('T')[0];
        if (data.productImageList && data.productImageList.length > 0) {
            setImageUrlError(false);
            data.productCreateImageList = imageUrls.map((url) => ({
                imageUrl: url,
                main: false,
            }));
        } else {
            if (imageUrls.length === 0) {
                setImageUrlError(true);
                return;
            }
            data.productCreateImageList = imageUrls.map((url, index) => ({
                imageUrl: url,
                main: index === 0,
            }));
        }

        let response;
        if (id) {
            response = await PUT("/services/admin/api/product", data);
        } else {
            response = await POST("/services/admin/api/product", data);
        }
        if (response) {
            history.push("/admin/products");
        }
    }

    async function getData() {
        store.dispatch(showLoader());
        if (id) {
            await getProduct();
            await getCategories();
        } else {
            await getRegions();
            await getShops();
            await getCategories();
            await getBazars();
        }
        store.dispatch(hideLoader());
    }

    async function deleteImage(id) {
        await DELETE("/services/admin/api/product/image/" + id);
        getData();
    }

    async function makeActive(id) {
        const payload = {
            accessed: true,
            id: id
        };
        await PUT("/services/admin/api/product/status", payload);
        getData();
    }
    async function makeNonActive(id) {
        const payload = {
            accessed: false,
            id: id
        };
        await PUT("/services/admin/api/product/status", payload);
        getData();
    }

    async function makeMain(id) {
        await PUT("/services/admin/api/product-image-main/" + id + '/' + resData.id);
        getData();
    }

    useEffect(() => {
        setImageUrlError(false);
    }, [imageUrls]);

    useEffect(() => {
        getData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const productId = resData?.id
    const productStatus = resData?.accessed
    return (
        <div className="mb-2 mx-3 ">
            <div style={{display: 'flex', width: "100%", justifyContent: "space-between"}}>
                <h3 className="mb-3 mt-1">Добавить новый</h3>


                {productStatus ?
                    (
                        <Button className="bg-dark" onClick={() => makeNonActive(productId)}>Отменить подтверждение</Button>
                    ) : (
                        <Button className="bg-dark" onClick={() => makeActive(productId)}>Подтвердить</Button>
                    )
                }
            </div>
            <div className="admin-card p-4">
                <form onSubmit={handleSubmit(create)} autoComplete="off" action="">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Название товара(UZ)</label>
                                    </div>
                                    <input
                                        {...register("nameUz", {required: true})}
                                        type="text"
                                        placeholder=""
                                        className="input-control"
                                    />
                                </div>
                                {errors.nameUz?.type === "required" && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Название товара(RU)</label>
                                    </div>
                                    <input
                                        {...register("nameRu", {required: true})}
                                        type="text"
                                        placeholder=""
                                        className="input-control"
                                    />
                                </div>
                                {errors.nameRu?.type === "required" && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Название товара(EN)</label>
                                    </div>
                                    <input
                                        {...register("nameEn")}
                                        type="text"
                                        placeholder=""
                                        className="input-control"
                                    />
                                </div>
                                {errors.nameEn?.type === "required" && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Контактный номер</label>
                                    </div>
                                    <input
                                        {...register("contactPhone", {required: true})}
                                        type="number"
                                        placeholder=""
                                        className="input-control"
                                    />
                                </div>
                                {errors.contactPhone?.type === "required" && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Старая цена</label>
                                    </div>
                                    <input
                                        {...register("oldPrice", {required: true})}
                                        type="number"
                                        placeholder="сум"
                                        className="input-control"
                                    />
                                </div>
                                {errors.oldPrice?.type === "required" && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Новая цена</label>
                                    </div>
                                    <input
                                        {...register("newPrice", {required: true})}
                                        type="number"
                                        placeholder="сум"
                                        className="input-control"
                                    />
                                </div>
                                {errors.newPrice?.type === "required" && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Регион</label>
                                    </div>
                                    <Select
                                        className="admin-select"
                                        options={regions}
                                        value={regions.find(region => region.value === getValues('regionId'))}
                                        onChange={(selectedOption) => {
                                            setValue("regionId", selectedOption.value);
                                            getCities(selectedOption.value);  // fetch cities based on the selected region
                                            // Clear shops as the region has changed and it will lead to changes in city and bazar
                                            setShops([]);
                                        }}
                                    />
                                </div>
                                {errors.regionId?.type === "required" && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Город (район)</label>
                                    </div>
                                    <Select
                                        className="admin-select"
                                        options={cities}
                                        value={cities.find(city => city.value === getValues('cityId'))}
                                        onChange={(selectedOption) => {
                                            setValue("cityId", selectedOption.value);
                                            getBazars(selectedOption.value);  // fetch bazars based on the selected city

                                            // Clear shops as the city (and hence bazar) might have changed
                                            setShops([]);
                                        }}
                                    />
                                </div>
                                {errors.cityId?.type === "required" && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Базар</label>
                                    </div>
                                    <Select
                                        className="admin-select"
                                        options={bazars}
                                        value={bazars.find(bazar => bazar.value === getValues('bozorId'))}
                                        onChange={(selectedOption) => {
                                            setValue("bozorId", selectedOption.value);
                                            getShops(selectedOption.value);   // fetch shops based on the selected bazar
                                        }}
                                    />
                                </div>
                                {errors.bozorId?.type === "required" && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>

                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Магазины</label>
                                    </div>
                                    <Select
                                        className="admin-select"
                                        options={shops}
                                        value={shops.find(shop => shop.value === getValues('shopId'))}
                                        onChange={(selectedOption) => {
                                            setValue("shopId", selectedOption.value);
                                        }}
                                    />
                                </div>
                                {errors.shopId?.type === "required" && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>

                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3"><label className="mb-0">Дата начало
                                        публикации</label></div>
                                    {/* <input {...register("birthday", { required: true })} type="date" className="input-control" /> */}
                                    <DatePicker
                                        className='input-control'
                                        showYearDropdown
                                        dateFormatCalendar="MMMM"
                                        yearDropdownItemNumber={60}
                                        scrollableYearDropdown
                                        maxDate={new Date(lastDateOfYear)}
                                        // maxDate={moment().startOf('year')}
                                        dateFormat="yyyy-MM-dd"
                                        selected={publishDate}
                                        onChange={(date) => (setPublishDate(date) + setValue('publishDate', moment(date).format("YYYY-MM-DD")))}/>
                                </div>
                                {errors.publishDate?.type === 'required' &&
                                    <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
                            </div>


                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3"><label className="mb-0">Дата окончания
                                        публикации</label></div>
                                    {/* <input {...register("birthday", { required: true })} type="date" className="input-control" /> */}
                                    <DatePicker
                                        className='input-control'
                                        showYearDropdown
                                        dateFormatCalendar="MMMM"
                                        yearDropdownItemNumber={60}
                                        scrollableYearDropdown
                                        maxDate={new Date(lastDateOfYear)}
                                        // maxDate={moment().startOf('year')}
                                        dateFormat="yyyy-MM-dd"
                                        selected={pickedDate}
                                        onChange={(date) => (setPickedDate(date) + setValue('expireDate', moment(date).format("YYYY-MM-DD")))}/>
                                </div>
                                {errors.expireDate?.type === 'required' &&
                                    <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
                            </div>

                        </div>
                        <div className="col-lg-6">
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Описание товара(UZ)</label>
                                    </div>
                                    <textarea
                                        {...register("descriptionUz", {required: true})}
                                        type="text"
                                        placeholder=""
                                        className="input-control"
                                    />
                                </div>
                                {errors.descriptionUz?.type === "required" && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Описание товара(RU)</label>
                                    </div>
                                    <textarea
                                        {...register("descriptionRu", {required: true})}
                                        type="text"
                                        placeholder=""
                                        className="input-control"
                                    />
                                </div>
                                {errors.descriptionRu?.type === "required" && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Описание товара(EN)</label>
                                    </div>
                                    <textarea
                                        {...register("descriptionEn", {required: true})}
                                        type="text"
                                        placeholder=""
                                        className="input-control"
                                    />
                                </div>
                                {errors.descriptionEn?.type === "required" && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3"><label className="mb-0">Категория</label></div>
                                    <div className="w-100 position-relative">
                                        <TreeSelect
                                            style={{width: '100%'}}
                                            value={category}
                                            dropdownStyle={{maxHeight: 500, overflow: 'auto'}}
                                            placeholder="Please select"
                                            treeDefaultExpandAll
                                            onChange={onChange}
                                            showSearch={false}
                                        >
                                            {show &&
                                                categories.map((item, index) => {
                                                    return (
                                                        <TreeNode key={item.id} value={item.id} title={item.name}>
                                                            {
                                                                item.children.length &&
                                                                item.children.map((item2, index2) => {
                                                                    return (
                                                                        <TreeNode key={item2.id} value={item2.id}
                                                                                  title={item2.name}></TreeNode>
                                                                    )
                                                                })
                                                            }
                                                        </TreeNode>
                                                    )
                                                })
                                            }
                                        </TreeSelect>
                                    </div>
                                </div>
                            </div>
                            <div className="admin-field d-flex">
                                <div className="d-flex w-100">
                                    <div className='admin-label'><label className="mb-0">Фото</label></div>
                                    <div className='input-control border-none'>
                                        <div className="position-relative">
                                            <Fileinput
                                                name="mainImage"
                                                selectedFiles={selectedFiles}
                                                onChange={handleFileChange}
                                                multiple
                                                preview
                                                imageUrls={imageUrls}
                                                setSelectedFiles={setSelectedFiles}
                                                setImageUrls={setImageUrls}
                                            />
                                        </div>
                                        {imageUrlError && (
                                            <div className="text-danger mt-1 fz12">
                                                Обязательное поле
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{width: 1200, height: "100%", display: "flex", flexDirection: "row", overflowX: 'auto'}}>
                        {!resData || resData.productImageList.map((image, index) => {
                            const items = [
                                {
                                    label: (
                                        <button
                                            style={{border: 'none', outline: 'none', background: "transparent"}}
                                            onClick={() => makeMain(image.id)}
                                        >
                                            Сделать главной
                                        </button>
                                    ),
                                    key: '0',
                                },
                                {
                                    label: (
                                        <button
                                            style={{border: 'none', outline: 'none', background: "transparent"}}
                                            onClick={() => deleteImage(image.id)}
                                        >
                                            Удалить
                                        </button>
                                    ),
                                    key: '1',
                                }
                            ];
                            return (
                                <div key={image.id} style={{position: 'relative', display: 'inline-block'}}>
                                    <div style={{height: 300}}>
                                        <div
                                            style={{
                                                width: 200,
                                                height: 200,
                                                border: "3px solid #c9c9c9",
                                                borderRadius: 5,
                                                marginRight: 20,
                                                background: `url(https://bozormedia.uz${image.imageUrl})`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                                position: "relative",
                                            }}
                                        >
                                            {image.main ? (
                                                <FontAwesomeIcon
                                                    icon={faStar}
                                                    size="lg"
                                                    style={{
                                                        color: '#1976d2',
                                                        cursor: 'pointer',
                                                        position: 'absolute',
                                                        right: 10,
                                                        top: 10,
                                                    }}
                                                />) : null}
                                            {image.main ? null : (
                                                <Dropdown
                                                    menu={{
                                                        items,
                                                    }}
                                                    trigger={['click']}
                                                >
                                                    <a onClick={(e) => e.preventDefault()}
                                                       style={{
                                                           position: "absolute",
                                                           right: -10,
                                                           bottom: -10,
                                                           width: 30,
                                                           height: 30,
                                                           display: 'flex',
                                                           alignItems: "center",
                                                           justifyContent: 'center',
                                                           borderRadius: '50%',
                                                           backgroundColor: "#bbdefb"
                                                       }}>
                                                        <FontAwesomeIcon
                                                            icon={faPen}
                                                            style={{
                                                                width: 16,
                                                                height: 16,
                                                                color: '#42a5f5',
                                                                cursor: 'pointer',
                                                                position: "absolute",
                                                            }}
                                                        />
                                                    </a>
                                                </Dropdown>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <div className="btn-postion d-flex">
                        <Link to="/admin/products" className="mr-3">
                            <Button className="btn bg-dark d-flex align-items-center">
                                <XCircle size={14} className="mr-2"/>
                                Отмена
                            </Button>
                        </Link>
                        <Button
                            type="submit"
                            className="btn bg-primary d-flex align-items-center"
                        >
                            <Save size={14} className="mr-2"/>
                            Сохранить
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ProductsCrud;
