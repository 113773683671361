import React, {useState, useEffect, useRef} from 'react'
import {Link, useParams, useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {XCircle, Save} from 'react-feather';
import {POST, GET, PUT, FILE} from '../../../api/adminApi'
import '../../../css/antd.css';
import {Button} from "react-bootstrap";
import {useSelector} from "react-redux";

function BazarOptionsCrud() {
    let {id} = useParams();
    const inputRef = useRef();
    let history = useHistory();
    const [data, setData] = useState({
        imageUrl: '',
    })
    const [resImage, setResImage] = useState()
    const bazarId = useSelector((state) => state.shop);
    const [categories, setCategories] = useState([{id: 0, parentId: null, name: 'Нет'}]);
    const {register, handleSubmit, formState: {errors}, setValue} = useForm()
    const [optionId, setOptionId] = useState('');

    async function getCategories() {
        const response = await GET("/services/admin/api/options-helper", false);
        setCategories(response);
    }

    async function create(data) {
        let response;
        data.bozorId = bazarId;
        if (id) {
            response = await PUT('/services/admin/api/options', data)
        } else {
            response = await POST('/services/admin/api/options', data)
        }
        if (response) {
            history.push('/admin/bazar/options', response)
        }
    }

    useEffect(() => {
        if (categories.length > 0) {
            setOptionId(categories[0].id); // Set the first element's id as the default value
            setValue("optionId", categories[0].id); // Set the value in the form context
        }
    }, [categories]);

    async function getProduct() {
        const response = await GET('/services/admin/api/bazar/option/' + id)
        setResImage(response.imageUrl)
        for (let key in response) {
            setValue(`${key}`, response[key])
        }
    }

    async function getImageUrl(e) {
        if (e.target.files[0]) {
            const image = e.target.files[0];
            const formData = new FormData();
            formData.append('image', image);
            const response = await FILE("/services/admin/api/options-image-upload", formData);
            setData({...data, imageUrl: response.url})
            setValue('imageUrl', response.url)
        }
    }

    console.log(resImage)
    useEffect(() => {
        getCategories()
        if (id) {
            getProduct()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="mb-2 mx-3 ">
            <h3 className="mb-3 mt-1">{id ? 'Редактировать' : 'Добавить новый'}</h3>
            <div className="admin-card p-4 overflow-y-auto" style={{height: "80vh"}}>
                <form onSubmit={handleSubmit(create)} action="" autoComplete='off'>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3"><label className="mb-0">Название (UZ)</label>
                                    </div>
                                    <input {...register("optionNameUz", {required: true})} type="text"
                                           className="input-control"/>
                                </div>
                                {errors.optionNameUz?.type === 'required' &&
                                    <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
                            </div>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3"><label className="mb-0">Название (RU)</label>
                                    </div>
                                    <input {...register("optionNameRu", {required: true})} type="text"
                                           className="input-control"/>
                                </div>
                                {errors.optionNameRu?.type === 'required' &&
                                    <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
                            </div>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3"><label className="mb-0">Название (EN)</label>
                                    </div>
                                    <input {...register("optionNameEn", {required: true})} type="text"
                                           className="input-control"/>
                                </div>
                                {errors.optionNameEn?.type === 'required' &&
                                    <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
                            </div>

                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Опции</label>
                                    </div>
                                    <select
                                        value={optionId}
                                        className="admin-select"
                                        {...register("optionId", {required: true})}
                                        onChange={(e) => {
                                            setOptionId(e.target.value);
                                            setValue("optionId", e.target.value);
                                        }}
                                    >
                                        {categories.map((item) => {
                                            return (
                                                <option key={item.id} value={item.id}>
                                                    {item.name}{" "}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                {errors.optionId?.type === "required" && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                            <div className="admin-field d-flex">
                                <div className="d-flex w-100">
                                    <div className='admin-label'><label className="mb-0">Главная фото</label></div>
                                    <div className='input-control border-none'>
                                        <div className="position-relative">
                                            <div onClick={() => inputRef.current.click()} className="image-block"
                                                 style={{
                                                     width: '100%',
                                                     maxWidth: '300px',
                                                     height: '200px',
                                                     objectFit: "contain",
                                                     zIndex: 1
                                                 }}>Перетащите сюда файлы для загрузки
                                            </div>
                                            <input style={{
                                                width: '100%',
                                                maxWidth: '300px',
                                                height: '200px', position: "absolute"
                                            }} ref={inputRef} onChange={(e) => getImageUrl(e)} type="file"
                                                   className="image-input"/>
                                            {data.imageUrl &&
                                                <div className="position-absolute cursor border-radius-3" style={{
                                                    width: '100%',
                                                    maxWidth: '300px',
                                                    height: '200px',
                                                    top: 0,
                                                    zIndex: 2
                                                }}>
                                                    {resImage ? (
                                                        <div
                                                            style={{objectFit: "contain", background: `https://bozormedia.uz${resImage}` }}
                                                            className="w-100 h-100 background-white border box-shadow admin-image"
                                                        />
                                                    ) : (
                                                        <img
                                                            style={{objectFit: "contain"}}
                                                            className="w-100 h-100 background-white border box-shadow admin-image"
                                                            src={`https://bozormedia.uz${data.imageUrl}`} alt=""/>
                                                    )}

                                                </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="btn-postion d-flex">
                        <Link to='/admin/bazar/options' className="mr-3"><Button
                            className="btn bg-dark d-flex align-items-center"><XCircle size={14} className="mr-2"/>Отмена
                        </Button></Link>
                        <Button type='submit' className="btn bg-primary d-flex align-items-center"><Save size={14}
                                                                                                         className="mr-2"/>Сохранить
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default BazarOptionsCrud
