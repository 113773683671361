import React from 'react'
import { Redirect, Route } from 'react-router-dom'

const isAuthenticated = () => {
  const token = localStorage.getItem('access_token');
  if (token) {
    return true;
  } else {
    return false;
  }
}

const isAdminAuthenticated = () => {
  const token = localStorage.getItem('admin_access_token');
  if (token) {
    return true;
  } else {
    return false;
  }
}

const isOperatorAuthenticated = () => {
  const token = localStorage.getItem('operator_access_token');
  if (token) {
    return true;
  } else {
    return false;
  }
}

function checkUserRole(rest) {
  if (rest.path.includes('/admin')) {
    if (JSON.parse(localStorage.getItem('admin_user')).role === 'ADMIN') {
      return "ADMIN"
    }
  }
  if (rest.path.includes('/operator')) {
    if (JSON.parse(localStorage.getItem('operator_user')).role === 'OPERATOR') {
      return "OPERATOR"
    }
  }
  if (JSON.parse(localStorage.getItem('user')).role === 'USER') {
    return "USER"
  }
}

export function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/"
            }}
          />
        )
      }
    />
  );
}

export function AdminPrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        isAdminAuthenticated() ? (
          rest.path.includes('/admin') && checkUserRole(rest) === "ADMIN" ?
            <Component {...props} />
            :
            <Redirect
              to={{
                pathname: "/admin/login"
              }}
            />
        ) : (
          <Redirect
            to={{
              pathname: "/admin/login"
            }}
          />
        )
      }
    />
  );
}

export function OperatorPrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        isOperatorAuthenticated() ? (
          rest.path.includes('/operator') && checkUserRole(rest) === "OPERATOR" ?
            <Component {...props} />
            :
            <Redirect
              to={{
                pathname: "/admin/login"
              }}
            />
        ) : (
          <Redirect
            to={{
              pathname: "/admin/login"
            }}
          />
        )
      }
    />
  );
}