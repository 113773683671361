import React, { useState, useEffect } from 'react'
import { Link, useParams, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { XCircle, Save } from 'react-feather';

import { POST, GET, PUT } from '../../../api/adminApi'

import '../../../css/antd.css';
import {Button} from "react-bootstrap";


export default function OptionsCrud() {
    let { id } = useParams();
    let history = useHistory();
    const [data, setData] = useState({
        imageUrl: '',
    })
    const { register, handleSubmit, formState: { errors }, setValue } = useForm()


    async function create(data) {
        let response;
        if (id) {
            response = await PUT('/services/admin/api/options', data)
        } else {
            response = await POST('/services/admin/api/options', data)
        }
        if (response) {
            history.push('/admin/options')
        }
    }

    async function getProduct() {
        const response = await GET('/services/admin/api/options/' + id)
        for (let key in response) {
            setValue(`${key}`, response[key])
        }
    }

    useEffect(() => {
        if (id) {
            getProduct()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="mb-2 mx-3 ">
            <h3 className="mb-3 mt-1">Добавить новый</h3>
            <div className="admin-card p-4 overflow-y-auto" style={{ height: "80vh" }} >
                <form onSubmit={handleSubmit(create)} action="" autoComplete='off'>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3"><label className="mb-0">Название (UZ)</label></div>
                                    <input {...register("nameUz", { required: true })} type="text" className="input-control" />
                                </div>
                                {errors.nameUz?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
                            </div>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3"><label className="mb-0">Название (RU)</label></div>
                                    <input {...register("nameRu", { required: true })} type="text" className="input-control" />
                                </div>
                                {errors.nameRu?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
                            </div>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3"><label className="mb-0">Название (EN)</label></div>
                                    <input {...register("nameEn", { required: true })} type="text" className="input-control" />
                                </div>
                                {errors.nameRu?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3"><label className="mb-0">Описание (UZ)</label></div>
                                    <textarea {...register("descriptionUz", { required: true })} type="text" className="input-control" />
                                </div>
                                {errors.descriptionUz?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
                            </div>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3"><label className="mb-0">Описание (RU)</label></div>
                                    <textarea {...register("descriptionRu", { required: true })} type="text" className="input-control" />
                                </div>
                                {errors.descriptionRu?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
                            </div>
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3"><label className="mb-0">Описание (EN)</label></div>
                                    <textarea {...register("descriptionEn", { required: true })} type="text" className="input-control" />
                                </div>
                                {errors.descriptionUz?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>}
                            </div>
                        </div>
                    </div>
                    <div className="btn-postion d-flex">
                        <Link to='/admin/options' className="mr-3"><Button className="btn bg-dark d-flex align-items-center"><XCircle size={14} className="mr-2" />Отмена </Button></Link>
                        <Button type='submit' className="btn bg-primary d-flex align-items-center"><Save size={14} className="mr-2" />Сохранить </Button>
                    </div>
                </form>
            </div>
        </div>
    )
}

