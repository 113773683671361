import React, { useState, useEffect } from 'react'
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { XCircle, Save } from 'react-feather';
import { POST, GET } from '../../../api/adminApi'
import '../../../css/antd.css';
import {Button} from "react-bootstrap";
import {useSelector} from "react-redux";
import {TreeSelect} from "antd";
const TreeNode = TreeSelect.TreeNode;
function BazarCategoriesCrud() {
    let history = useHistory();
    const bazarId = useSelector((state) => state.shop);
    // const [categories, setCategories] = useState([{ id: 0, parentId: null, name: 'Нет' }]);
    const { register, handleSubmit, formState: { errors }, setValue } = useForm()
    const [optionId, setOptionId] = useState('');
    const [categories, setCategories] = useState([{name: 'Нет', parentId: 0, id: 0}])
    const [category, setCategory] = useState()
    const [show, setShow] = useState(false)

    const onChange = (string) => {
        setCategory(string)
        setValue('categoryId', string)
    };

    function sortCategories(arr) {
        var tree = [{name: 'Нет', id: 0, children: []}],
            treeChild = []
        for (var i = 0, len = arr.length; i < len; i++) {
            if (arr[i].parentId === 0) {
                arr[i]['children'] = []
                tree.push(arr[i]);
            } else {
                // arr[i]['children'] = []
                treeChild.push(arr[i])
            }
        }
        for (let i = 0; i < treeChild.length; i++) {
            for (let j = 0; j < treeChild.length; j++) {
                if (treeChild[i].id === treeChild[j].parentId) {
                    treeChild[i]['children'] = []
                }
            }
        }

        for (let i = 0; i < treeChild.length; i++) {
            for (let j = 0; j < treeChild.length; j++) {
                if (treeChild[i].id === treeChild[j].parentId) {
                    treeChild[i]['children'].push(treeChild[j])
                }
            }
        }

        for (let i = 0; i < tree.length; i++) {
            for (let j = 0; j < treeChild.length; j++) {
                if (tree[i]['id'] === treeChild[j]['parentId']) {
                    tree[i]['children'].push(treeChild[j])
                }
            }
        }
        setCategories(tree)
        setShow(true)
        return tree;
    }

    async function getCategories() {
        const categories = await GET('/services/admin/api/categories-helper')
        sortCategories(categories)
    }
    async function create(data) {
        let response;
        data.bozorId = bazarId;
        response = await POST('/services/admin/api/bazar/category', data)
        if (response) {
            history.push('/admin/bazar/category', response)
        }
    }
    useEffect(() => {
        if (categories.length > 0) {
            setOptionId(categories[0].id); // Set the first element's id as the default value
            setValue("optionId", categories[0].id); // Set the value in the form context
        }
    }, [categories]);

    useEffect(() => {
        getCategories()

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="mb-2 mx-3 ">
            <h3 className="mb-3 mt-1">Добавить новый</h3>
            <div className="admin-card p-4 overflow-y-auto" style={{ height: "80vh" }} >
                <form onSubmit={handleSubmit(create)} action="" autoComplete='off'>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <div className="admin-field">
                                <div className="d-flex w-100">
                                    <div className="admin-label mr-md-3">
                                        <label className="mb-0">Категории</label>
                                    </div>
                                    <TreeSelect
                                        style={{width: '100%'}}
                                        value={category}
                                        dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                        placeholder="Please select"
                                        treeDefaultExpandAll
                                        onChange={onChange}
                                        showSearch={false}
                                    >
                                        {show &&
                                            categories.map((item, index) => {
                                                return (
                                                    <TreeNode key={item.id} value={item.id} title={item.name}>
                                                        {
                                                            item.children.length &&
                                                            item.children.map((item2, index2) => {
                                                                return (
                                                                    <TreeNode key={item2.id} value={item2.id}
                                                                              title={item2.name}></TreeNode>
                                                                )
                                                            })
                                                        }
                                                    </TreeNode>
                                                )
                                            })
                                        }
                                    </TreeSelect>
                                </div>
                                {errors.regionId?.type === "required" && (
                                    <div className="text-danger mt-1 fz12">
                                        Обязательное поле
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="btn-postion d-flex">
                        <Link to='/admin/bazar/options' className="mr-3"><Button className="btn bg-dark d-flex align-items-center"><XCircle size={14} className="mr-2" />Отмена </Button></Link>
                        <Button type='submit' className="btn bg-primary d-flex align-items-center"><Save size={14} className="mr-2" />Сохранить </Button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default BazarCategoriesCrud
